<div>
  <div class="row">
    <div class="col-md-4">
      <span class="hipster img-fluid rounded"></span>
    </div>

    <div class="col-md-8">
      <h1 jhiTranslate="error.title">Error page!</h1>

      @if (errorMessage) {
        <div class="alert alert-danger">{{ errorMessage }}</div>
      }
    </div>
  </div>
</div>
