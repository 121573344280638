import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Account } from '../../core/auth/account.model';
import { AccountService } from '../../core/auth/account.service';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { ProfileService } from '../../profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'jhi-profile',
  standalone: true,
  imports: [FormsModule, NgbModule, CommonModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfileComponent implements OnInit {
  @ViewChild('modalDeleteAccount') modalDeleteAccount: any;
  @ViewChild('ConfirmationModalComponent') ConfirmationModalComponent: any;
  changePasswordForm = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  phoneNumber: string = '';
  code: string = '';
  profileImageUrl: string | ArrayBuffer | null = null;
  account!: Account;
  confirmationCode: string = '';
  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  constructor(
    private authService: AuthServerProvider,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.loadProfile();
  }

  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
    });
  }
  onImgSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        if (e.target && e.target.result) {
          this.profileImageUrl = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    }
  }
  updateProfile(): void {
    this.accountService.save(this.account).subscribe(res => {
      this.accountService.setAccount(this.account);
      this.loadProfile();
      // this.toastrService.success('Profile updated successfully');
    });
  }
  updateAccount(): void {
    const email: string = this.account.email ?? '';
    const phoneNumber: string = this.account.applicationUser?.phoneNumber ?? '';
    this.accountService.updateContact(email, phoneNumber).subscribe({
      next: () => {
        this.updateProfile();
        if (phoneNumber) {
          this.openConfirmationModal();
        } else this.toastrService.success('Profile updated successfully');
      },
      error: err => {
        this.toastrService.error('Error updating profile');
      },
    });
  }

  openConfirmationModal(): void {
    const modalRef = this.modalService.open(this.ConfirmationModalComponent);
    modalRef.componentInstance.phoneNumber = this.account.applicationUser.phoneNumber;

    modalRef.result.then(result => {
      if (result) {
        this.confirmPhoneNumber(this.account.applicationUser?.phoneNumber ?? '', result);
      }
    });
  }

  confirmPhoneNumber(phoneNumber: string, code: string): void {
    this.accountService.confirmContact('968965555', code).subscribe({
      next: () => this.toastrService.success('Phone number confirmed successfully'),
      error: () => this.toastrService.error('Error confirming phone number'),
    });
  }

  deleteAccountClient() {
    this.accountService.deleteAccount().subscribe({
      next: () => {
        this.toastrService.success('Account deleted successfully');
        this.authService.logout();
      },
      error: error => {
        this.toastrService.error('Error deleting account');
      },
    });
  }
  toggleShowPassword(type: string) {
    if (type === 'current') {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else if (type === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (type === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
  changePassword() {
    if (this.changePasswordForm.newPassword !== this.changePasswordForm.confirmPassword) {
      alert('Les mots de passe ne correspondent pas');
      this.toastrService.error('Les mots de passe ne correspondent pas');
      return;
    }

    this.profileService.changePassword(this.changePasswordForm.currentPassword, this.changePasswordForm.newPassword).subscribe(
      response => {
        this.toastrService.success('Mot de passe changé avec succès');
      },
      error => {
        this.toastrService.error('Erreur lors du changement de mot de passe');
      },
    );
  }
  openModal(modal: any) {
    this.modalService.open(modal, { size: 's', centered: true });
  }
}
