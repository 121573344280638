import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountService } from '../../core/auth/account.service';
import SharedModule from '../../shared/shared.module';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  selector: 'jhi-footer',
  templateUrl: './footer.component.html',
  imports: [SharedModule, CommonModule],
  styles: [``],
})
export default class FooterComponent implements OnInit {
  disable = false;
  account: any;
  currentUrl: any;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  isLandComponentActive = false;
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.disable =
        event.url.includes('/account/register') ||
        event.url.includes('/login') ||
        event.url === '/login?user=completeCreation' ||
        event.url.includes('/account/activate') ||
        event.url.includes('admin');
      this.currentUrl = event.url;
      console.log(event.url);
      this.checkIfLandComponentActive();
    });
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      if (account) {
        this.disable = account.authorities.includes('ROLE_ADMIN');
        console.log(this.account);
      }
    });
  }
  private checkIfLandComponentActive(): void {
    this.isLandComponentActive = localStorage.getItem('isLandComponentActive') === 'true';
  }
}
