import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SocketService } from '../socket.service';
import { OrdersService } from '../orders.service';
import { AccountService } from '../../core/auth/account.service';
import { Account } from '../../core/auth/account.model';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
interface StatusMapping {
  userStatus: string;
  actions: string[];
}

@Component({
  selector: 'jhi-order-details',
  standalone: true,
  imports: [NgbModule, CommonModule, FormsModule],
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  account!: Account;
  purchase_id: any;
  purchaseDetails: any;
  room: string = '';
  messages: any[] = [];
  message = '';
  @ViewChild('msgHistory') private msgHistory!: ElementRef;
  private messageSubscription!: Subscription;
  numCommande: any;
  fileUrlsFromOvh: string[] = [];
  isFilesExistFromOvh = false;
  prescriptionFilesFromOvh: any[] = [];
  vitalCardFilesFromOvh: any[] = [];
  mutualCardFilesFromOvh: any[] = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];

  statusMappings: Record<string, StatusMapping> = {
    DRAFT: { userStatus: 'PANIER', actions: ['CONFIRMER', 'ANNULER', 'VOIR DÉTAILS'] },
    CREATED_WITH_PRESCRIPTION: { userStatus: 'NOUVELLE', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    IN_PROGRESS: { userStatus: 'EN COURS', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    PAYMENT_INSTANCE: { userStatus: 'EN COURS', actions: ['PAYER', 'ANNULER'] },
    PACKAGING: { userStatus: 'PRETE', actions: ['VOIR DÉTAILS'] },
    READY: { userStatus: 'PRETE', actions: ['VOIR DÉTAILS'] },
    DELIVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    DELIVERED: { userStatus: 'ARCHIVE', actions: ['VOIR DÉTAILS'] },
    RECOVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    RECOVERED: { userStatus: 'ARCHIVE', actions: ['VOIR DÉTAILS'] },
    RETRACTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    CANCELED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    REJECTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    LOST: { userStatus: 'ARCHIVÉ', actions: [] },
    ARCHIVED: { userStatus: '---', actions: [] },
  };

  constructor(
    private socketService: SocketService,
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private accountService: AccountService,
    public cartService: CartService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getPurchaseId();
    this.loadProfile();

    this.route.paramMap.subscribe(params => {
      this.room = params.get('purchase_id')!;
      const query = `username=walid&room=${this.purchase_id}`;
      this.socketService.setQuery(query);
      this.initializeChat();
    });

    this.messageSubscription = this.socketService.socketResponse$.subscribe(response => {
      console.log(response.text, 'response.text');
      if (response) {
        this.messages.push({
          sender: response.user.name || 'Server',
          text: response.text,
          time: new Date(response.createdAt).toLocaleTimeString(),
          received: response.received,
        });
      }
    });

    this.numCommande = this.cartService.getNumCommande();
    this.getAllFilesUrlFromOvh();
  }
  getAllFilesUrlFromOvh(): void {
    this.orderService.getFileUrl(this.purchase_id).subscribe(res => {
      console.log('Réponse du serveur:', res); // Vérifiez la réponse du serveur
      this.prescriptionFilesFromOvh = res.prescription || [];
      this.vitalCardFilesFromOvh = res.vital_card || [];
      this.mutualCardFilesFromOvh = res.mutual_card || [];

      const files = [...res.prescription, ...res.vital_card, ...res.mutual_card];
      console.log('Clés de fichiers:', files); // Vérifiez les clés de fichiers

      if (files.length > 0) {
        this.isFilesExistFromOvh = true;
        this.prescriptionFilesFromOvh = res.prescription || [];
        this.fileUrlsFromOvh = [];
        console.log("Fichiers d'ordonnance:", this.prescriptionFilesFromOvh); // Vérifiez les fichiers d'ordonnance
        files.forEach((fileKey, index) => {
          this.orderService
            .getImage('test-documents-cmd-ordonnance', fileKey)
            .then(data => {
              const blob = new Blob([data.Body], { type: data.ContentType });
              const url = URL.createObjectURL(blob);
              this.fileUrlsFromOvh.push(url);
              console.log('URL de fichier:', url); // Vérifiez les URL créées

              if (this.prescriptionFiles.includes(fileKey)) {
                this.prescriptionFilesFromOvh[index] = url;
              } else if (this.vitalCardFiles.includes(fileKey)) {
                this.vitalCardFilesFromOvh[index] = url;
              } else if (this.mutualCardFiles.includes(fileKey)) {
                this.mutualCardFilesFromOvh[index] = url;
              }
            })
            .catch(err => {
              console.error("Erreur lors du chargement de l'image :", err);
            });
        });
      }
    });
  }
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
    });
  }

  getPurchaseId(): void {
    this.route.paramMap.subscribe(params => {
      this.purchase_id = +params.get('purchase_id')!;
      this.getPurchaseDetails(this.purchase_id);
    });
  }

  getPurchaseDetails(purchase_id: any): void {
    this.orderService.getPurchaseById(purchase_id).subscribe({
      next: res => {
        this.purchaseDetails = res;
      },
      error: err => {
        console.error(err);
      },
    });
  }

  getUserStatus(status: string): string {
    return this.statusMappings[status]?.userStatus || 'UNKNOWN';
  }

  getActions(status: string): string[] {
    return this.statusMappings[status]?.actions || [];
  }

  initializeChat(): void {
    this.socketService.joinRoom(this.room);
    // this.socketService.getMessagesForRoom(this.room).subscribe(
    //   messages => {
    //     this.messages = messages.map((msg: any) => ({
    //       sender: 'Pharmacie',
    //       text: msg.content,
    //       time: new Date(msg.createdDateTime).toLocaleTimeString(),
    //     }));
    //     // this.scrollToBottom();
    //   },
    //   error => {
    //     console.error('Error fetching messages:', error);
    //   },
    // );
  }

  sendMessage(): void {
    if (this.message.trim()) {
      let data = {
        message: this.message,
        content: this.message,
        userId: this.account.id,
        room: this.room,
        platforme: 'web',
      };
      this.socketService.sendMessage(data);
      this.messages.push({
        sender: 'Vous',
        text: this.message,
        time: new Date().toLocaleTimeString(),
      });
      this.message = '';
      // this.scrollToBottom();
    }
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }

  // private scrollToBottom(): void {
  //   try {
  //     this.msgHistory.nativeElement.scrollTop = this.msgHistory.nativeElement.scrollHeight;
  //   } catch (err) {
  //     console.error('Error scrolling to bottom:', err);
  //   }
  // }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this.socketService.disconnect();
  }
}
