<div class="row mt-5 mb-4">
  <div class="col-sm-12 col-md-5">
    <h2 class="lead" style="color: #141414">
      <img
        (click)="navigateToPurchaseLite()"
        src="../../../../content/images/icons/arrow-left-solid.svg"
        alt="upload"
        width="15px"
        style="margin-right: 10px; cursor: pointer"
      />
      Détails Commande
    </h2>
  </div>
  <div class="col-sm-12 col-md-5 d-sm-flex flex-column flex-sm-row">
    <span class="command-id">ID : {{ purchaseDetails?.id }}</span>
    <span
      class="badge"
      [ngClass]="{
        'bg-new': getUserStatus(purchaseDetails?.lastStatus?.name) === 'NOUVELLE',
        'bg-in-progress': getUserStatus(purchaseDetails?.lastStatus?.name) === 'EN COURS',
        'bg-prete': getUserStatus(purchaseDetails?.lastStatus?.name) === 'PRETE',
        'bg-embalage': getUserStatus(purchaseDetails?.lastStatus?.name) === 'EMBALLAGE',
        'bg-livraison': getUserStatus(purchaseDetails?.lastStatus?.name) === 'EN LIVRAISON',
        'bg-archived': getUserStatus(purchaseDetails?.lastStatus?.name) === 'ARCHIVE'
      }"
      >{{ getUserStatus(purchaseDetails?.lastStatus?.name) }}</span
    >
  </div>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #navCommandDetails="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Frais</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Titre</th>
                  <th scope="col">Prix Unitaire</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Prix TTC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Produits/Médicaments</td>
                  <td>{{ purchaseDetails?.productsAmount.toFixed(2) }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.productsAmount.toFixed(2) }}€</span>
                  </td>
                </tr>
                <tr>
                  <td>Frais de service</td>
                  <td>{{ purchaseDetails?.serviceFee }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.serviceFee }}€</span>
                  </td>
                </tr>
                <tr>
                  <td>Livraison</td>
                  <td>{{ purchaseDetails?.deliveryFee }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.deliveryFee }}€</span>
                  </td>
                </tr>
                <tr *ngIf="purchaseDetails?.prescriptionExtraAmount">
                  <td>Supplément</td>
                  <td></td>
                  <td></td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.prescriptionExtraAmount }}€</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td style="background-color: #fbfbfb"><span class="total">Totale</span></td>
                  <td style="background-color: #fbfbfb">
                    <span class="total-price">{{ purchaseDetails?.totalAmount.toFixed(2) }}€</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            (click)="addAmount()"
            *ngIf="
              getUserStatus(purchaseDetails?.lastStatus?.name) !== 'NOUVELLE' &&
              getUserStatus(purchaseDetails?.lastStatus?.name) !== 'EMBALLAGE'
            "
            class="add-supplement"
          >
            <img src="../../../../content/images/upload.svg" alt="upload" /> Ajouter supplément
          </p>
          <div class="row">
            <div *ngIf="addAmountValue" class="col-3 chat-input-wrapper buttons">
              <input type="text" [(ngModel)]="extraAmount" placeholder="Amount" />
              <button class="add" (click)="addExtraAmount()">Ajouter</button>
            </div>
            <div class="col">
              <div class="buttons">
                <button class="ignore" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) !== 'EMBALLAGE'">Ignorer</button>
                <button class="ready" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) === 'NOUVELLE'">Accepter</button>
                <button class="ready" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) === 'EN COURS'">Prête</button>
                <button class="ready" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) === 'PRETE'">Emballer</button>
                <button class="ready" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) === 'EMBALLAGE'">Livrer</button>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Détails</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-sm-12 col-md-6 mt-3 mt-md-0">
              <div class="card">
                <div class="card-body">
                  <div class="command-summary">
                    <div class="d-flex flex-column">
                      <div class="summary">
                        <div class="summary-item header">
                          <p>
                            Date de création: <b>{{ purchaseDetails?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</b>
                          </p>
                          <button class="report-btn" (click)="openModal()">Signaler</button>
                        </div>
                        <div class="summary-item">
                          <p>
                            Prénom & Nom:
                            <b
                              >{{ purchaseDetails?.dest?.firstname ? purchaseDetails?.dest?.firstname : '---' }}
                              {{ purchaseDetails?.dest?.lastname ? purchaseDetails?.dest?.lastname : '---' }}</b
                            >
                          </p>
                        </div>
                        <div class="summary-item">
                          <p>
                            Adresse :
                            <b
                              >{{ purchaseDetails?.deliveryAddress?.streetNumber }} {{ purchaseDetails?.deliveryAddress?.streetName }}
                              {{ purchaseDetails?.deliveryAddress?.city }} {{ purchaseDetails?.deliveryAddress?.state }}
                              {{ purchaseDetails?.deliveryAddress?.postalCode }} {{ purchaseDetails?.deliveryAddress?.country }}</b
                            >
                          </p>
                        </div>
                        <div class="summary-item" *ngIf="fileUrlsFromOvh.length">
                          <p>Documents :</p>
                        </div>
                        <div class="documents">
                          <div class="document" *ngFor="let fileUrl of fileUrlsFromOvh; let i = index">
                            <img [src]="fileUrl" alt="Ordonnance Image" class="img-fluid" />
                            <button class="download-btn">&#8595;</button>
                            <button class="expand-btn">&#x21F2;</button>
                          </div>
                        </div>
                        <div>
                          <p class="warning">⚠️ Clientt signalé au 24/04/2024 pour avoir commandé une Ordonnance dupliquée</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="getUserStatus(purchaseDetails?.lastStatus?.name) !== 'NOUVELLE'">
        <a ngbNavLink>Notification</a>
        <ng-template ngbNavContent>
          <div class="chat-wrapper">
            <div class="chat-box">
              <div class="msges">
                <div class="msg_history" #msgHistory>
                  <div
                    *ngFor="let msg of messages"
                    [ngClass]="{
                      incoming_msg: msg.sender !== 'Vous',
                      outgoing_msg: msg.sender === 'Vous',
                      'justify-content-end': msg.sender === 'Vous'
                    }"
                    class="d-flex"
                  >
                    <div class="incoming_msg_img" *ngIf="msg.sender !== 'Vous'">
                      <img src="/content/images/user-2.png" alt="user" />
                    </div>
                    <div class="received_msg" *ngIf="msg.sender !== 'Vous'">
                      <div class="received_withd_msg">
                        <p>{{ msg.sender }} :</p>
                        <p>{{ msg.text }}</p>
                        <span class="time_date">{{ msg.time }}</span>
                      </div>
                    </div>
                    <div class="sent_msg" *ngIf="msg.sender === 'Vous'">
                      <p>{{ msg.sender }} :</p>
                      <p>{{ msg.text }}</p>
                      <span class="time_date">{{ msg.time }}</span>
                    </div>
                    <div class="sent_msg_img" *ngIf="msg.sender === 'Vous'">
                      <img src="/content/images/pharmacie-1.png" alt="pharmacy" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-input-wrapper">
              <input type="text" [(ngModel)]="message" placeholder="Type your message here..." (keydown.enter)="sendMessage()" />
              <button class="send-msge" (click)="sendMessage()"></button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navCommandDetails" class="mt-2"></div>
  </div>
</div>
<ng-template #modalCartVide let-modalCartVide>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalCartVide.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
    </div>
    <p class="swalModal">Signaler</p>
    <div [formGroup]="reportForm">
      <label for="Cause">Cause</label>
      <textarea id="w3review" formControlName="additionalComment" rows="4" cols="48"></textarea>
    </div>
    <div class="d-flex justify-content-between w-100">
      <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="submitReport(); modalCartVide.close('closeCart')">Signaler</button>
    </div>
  </div>
</ng-template>
<ng-template #modalDetailsCommande let-modalDetailsCommande>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center">
      <div class="title">
        Détails Commande
        <p class="command-id">ID: 0714528/24</p>
      </div>
    </div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalDetailsCommande.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center"></div>
    <div class="summaryCommande">
      <div class="summary-item-commande header">
        <p>
          Date de création: <b>{{ purchaseDetails?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</b>
        </p>
      </div>
      <div class="summary-item-commande">
        <p>
          Prénom & Nom:
          <b
            >{{ purchaseDetails?.dest?.firstname ? purchaseDetails?.dest?.firstname : '---' }}
            {{ purchaseDetails?.dest?.lastname ? purchaseDetails?.dest?.lastname : '---' }}</b
          >
        </p>
      </div>
      <div class="summary-item-commande">
        <p>Adresse : <b>12 rue de la lorem ipsum 12345, Paris, France </b></p>
      </div>
      <div class="summary-item-commande">
        <p>Documents :</p>
      </div>
      <div class="documents">
        <div class="document" *ngFor="let fileUrl of fileUrlsFromOvh; let i = index">
          <img [src]="fileUrl" alt="Ordonnance Image" class="img-fluid" />
          <button class="download-btn">&#8595;</button>
          <button class="expand-btn">&#x21F2;</button>
        </div>
      </div>
      <div class="buttonsDetails">
        <button class="ignoreCommande">Ignorer</button>
        <button class="readyCommande">Accepter</button>
      </div>
      <div>
        <p class="warning">⚠️ Clientt signalé au 24/04/2024 pour avoir commandé une Ordonnance dupliquée</p>
      </div>
    </div>
  </div>
</ng-template>
