<div class="modal-product">
  <div class="modal-header">
    <div class="col d-flex justify-content-center">
      <img src="../../content/images/success.svg" alt="" />
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <h3>Paiement Réussi</h3>
      <div class="col-12"></div>
      <div class="d-flex justify-content-between w-100"></div>

      <div class="d-flex justify-content-between w-100 down-input"></div>
      <p class="paiementText">Félicitations !</p>
      <p class="paiementText">Votre paiement a été effectué avec succès.</p>
      <button class="btn btn-success mt-3 saveAdresse" (click)="navigateToHome()" routerLinkActive="router-link-active">Retour</button>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</div>
