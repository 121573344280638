import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Location } from '@angular/common';

@Component({
  selector: 'jhi-pharmacie-sub-category',
  standalone: true,
  imports: [CommonModule, RouterModule, NgxSkeletonLoaderModule],
  templateUrl: './pharmacie-sub-category.component.html',
  styleUrl: './pharmacie-sub-category.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieSubCategoryComponent implements OnInit {
  pharmacyId!: number;
  categoryId!: number;
  // sousCategories: any;
  pharmacyName: any;
  catName: any;
  contentLoaded = false;
  sousCategories: any[] = [];
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private pharmacieService: HomeServiceService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 1000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.catName = this.pharmacieService.getCatName();
    this.getSubCategories();
  }

  goBack() {
    this.location.back();
  }
  getPharmacyId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.categoryId = +params.get('categoryId');
        console.log('pharmacieId', this.pharmacyId);
      },
    });
  }
  getSubCategories(): void {
    const selectedCategory = this.pharmacieService.getSelectedCategory();
    if (selectedCategory) {
      this.sousCategories = selectedCategory.subCategories
        .filter((subCat: any) => subCat.stockItemsAccount > 0)
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
    }
  }

  NavigateToProducts(subCategoryId: number, subCatName: any) {
    this.router.navigate(['/pharmacie-products', { pharmacieId: this.pharmacyId, subCategoryId: subCategoryId }]);
    this.setSubCatName(subCatName);
    this.setSubCatId(subCategoryId);
  }
  setSubCatName(subCatName: any) {
    this.pharmacieService.setSubCatName(subCatName);
  }
  setSubCatId(subCatId: any) {
    this.pharmacieService.setSubCatId(subCatId);
  }
  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const fileName = inputElement.files[0].name;
      const spanElement = inputElement.nextElementSibling as HTMLElement;

      if (spanElement) {
        spanElement.textContent = fileName;
      }
    }
  }
}
