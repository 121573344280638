import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../config/language.constants';
import { AccountService } from '../../core/auth/account.service';
import { StateStorageService } from '../../core/auth/state-storage.service';
import ActiveMenuDirective from '../../layouts/navbar/active-menu.directive';
import { LoginService } from '../../login/login.service';
import SharedModule from '../../shared/shared.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'jhi-login-admin',
  standalone: true,
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule, ActiveMenuDirective],
})
export default class LoginAdminComponent implements OnInit, AfterViewInit {
  languages = LANGUAGES;
  @ViewChild('username', { static: false })
  url: any;
  username!: ElementRef;

  authenticationError = false;

  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  delfaultLang: any;
  isNavbarCollapsed = true;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    private stateStorageService: StateStorageService,
    private translateService: TranslateService,
    private _toaster: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.delfaultLang = this.stateStorageService.getLocale()
      ? this.stateStorageService.getLocale()
      : this.translateService.getDefaultLang();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // if already authenticated then navigate to home page
    // this.accountService.identity().subscribe(() => {
    //   if (this.accountService.isAuthenticated()) {
    //     this.router.navigate(['']);
    //   }
    // });
  }

  ngAfterViewInit(): void {
    this.username.nativeElement.focus();
  }
  changeLanguage(languageKey: string): void {
    this.stateStorageService.storeLocale(languageKey);
    this.translateService.use(languageKey);
    this.delfaultLang = languageKey;
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          // There were no routing during login (eg from navigationToStoredUrl)
          this.router.navigate(['/admin/home']);
        }
      },
      error: () => {
        this.authenticationError = true;
        this._toaster.error(this.translateService.instant('login.messages.error.authentication'));
      },
    });
  }
}
