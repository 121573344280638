<div class="card pharmacy-card" style="border: 1px solid #e5e7eb; border-radius: 16px">
  <img src="../../content/images/pharmacie-1.png" class="card-img-top" alt="Image" />
  <div class="card-body" style="display: flex; flex-direction: column">
    <h5 class="card-title">Pharmacie du bien être</h5>
    <p class="card-text">123 Rue du Soleil, Ville</p>
    <a class="btn btn-light d-flex delivery">Livraison en 30m </a>
    <a class="btn btn-light d-flex likeBtn">
      <img [src]="likeImagePath" alt="Like" />
    </a>
    <a class="btn order" style="border: 2px solid #0078c6; border-radius: 20px; color: #0078c6">Passer commande</a>
    <a class="alert-link details">Voir détails</a>
  </div>
</div>
