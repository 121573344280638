import { Component, Inject, ElementRef, ViewChild, NgZone, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { geolib } from './geolib';
import { ApiService, Maps } from '../../home/service/map.service';
const colors = [
  'red',
  'blue',
  'green',
  'yellow',
  'brown',
  'BurlyWood',
  'Cyan',
  'DarkGreen',
  'DarkOrchid',
  'DarkOliveGreen',
  'Fuchsia',
  'GoldenRod',
  'Indigo',
  'LightCoral',
  'MediumSlateBlue',
];
let colorIndex = 0;

@Component({
  selector: 'auto-complete',
  template: `
    <div>
      <input
        placeholder="search for location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        class="form-control"
        (input)="forcedToStart()"
        #search
      />
    </div>
  `,
  styles: [
    `
      .map-container {
        z-index: 9999; /* Adjust the value as needed */
      }
      ::ng-deep .pac-container {
        z-index: 9999 !important;
      }
    `,
  ],
})
export class AutoCompleteComponent implements AfterViewInit {
  @ViewChild('search') public searchElementRef!: ElementRef;
  @ViewChild('map') public mapElementRef!: ElementRef;
  @Output() public placeSelected: any = new EventEmitter();
  @Input() public optionalInput: string | undefined;

  public entries = [];
  public place!: google.maps.places.PlaceResult;
  public locationFields = ['name', 'cityName', 'stateCode', 'countryName', 'countryCode'];

  constructor(
    private apiService: ApiService,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit() {
    this.apiService.api.then(maps => {
      this.initAutocomplete(maps);
    });

    if (this.optionalInput !== undefined) {
      console.log(this.optionalInput, 'test');
      this.searchElementRef.nativeElement.value = this.optionalInput;
    }
  }

  forcedToStart() {
    this.apiService.api.then(maps => {
      this.initAutocomplete(maps);
    });
  }

  initAutocomplete(maps: Maps) {
    const autocomplete = new maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['geocode'],
      componentRestrictions: { country: 'fr' },
    });

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        this.placeSelected.emit(place);
      });
    });
  }
}

function toEllipse({ north, south, east, west }: cosmos.LatLngBoundsLiteral) {
  const latitude = (north + south) / 2;
  const longitude = (east + west) / 2;
  const r1 = geolib.getDistance({ latitude: north, longitude }, { latitude: south, longitude }) / 2;
  const r2 = geolib.getDistance({ latitude, longitude: west }, { latitude, longitude: east }) / 2;

  const center = { latitude, longitude };
  const latitudeConv = geolib.getDistance(center, { latitude: latitude + 0.1, longitude }) * 10;
  const longitudeCong = geolib.getDistance(center, { latitude, longitude: longitude + 0.1 }) * 10;

  const points: cosmos.Coordinates[] = [];
  const FULL = Math.PI * 2;
  for (let i = 0; i <= FULL + 0.0001; i += FULL / 8) {
    points.push({
      latitude: latitude + (r1 * Math.cos(i)) / latitudeConv,
      longitude: longitude + (r2 * Math.sin(i)) / longitudeCong,
    });
  }
  return points;
}

function expandBounds(bounds: cosmos.LatLngBoundsLiteral, meters: number) {
  const SQRT_2 = 1.4142135623730951;
  const { longitude: west, latitude: north } = geolib.computeDestinationPoint(
    {
      latitude: bounds.north,
      longitude: bounds.west,
    },
    SQRT_2 * meters,
    315,
  );
  const { longitude: east, latitude: south } = geolib.computeDestinationPoint(
    {
      latitude: bounds.south,
      longitude: bounds.east,
    },
    SQRT_2 * meters,
    135,
  );
  return { west, north, east, south };
}

namespace cosmos {
  export interface Coordinates {
    /**
     * Coordinates latitude.
     * @type {number}
     */
    latitude: number;
    /**
     * Coordinates longitude.
     * @type {number}
     */
    longitude: number;
  }
  export interface LatLngBoundsLiteral {
    /**
     * LatLngBoundsLiteral east.
     * @type {number}
     */
    east: number;
    /**
     * LatLngBoundsLiteral north.
     * @type {number}
     */
    north: number;
    /**
     * LatLngBoundsLiteral south.
     * @type {number}
     */
    south: number;
    /**
     * LatLngBoundsLiteral west.
     * @type {number}
     */
    west: number;
  }
}
