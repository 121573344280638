import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebase);
@NgModule({
  imports: [AngularFireModule.initializeApp(environment.firebase), AngularFireMessagingModule],
  exports: [AngularFireModule, AngularFireMessagingModule],
})
export class FirebaseConfigModule {}
