<div>
  <div class="d-flex justify-content-center">
    <div
      class="col-md-4 registerLogin loginclass"
      [ngStyle]="{ 'margin-left': screenWidth > 1300 ? '2rem' : 'initial', padding: screenWidth > 1300 ? '1rem' : '0rem 3rem' }"
    >
      <div class="row d-flex header">
        <div class="col-6"><span routerLink="/" class="logo-pharma" style="margin: 3rem auto"></span></div>
        <div class="col d-flex align-items-center justify-content-end">
          <a ngbDropdown class="nav-item dropdown pointer" display="dynamic" style="text-decoration: none; color: unset">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
              <span>
                <span>{{ delfaultLang | findLanguageFromKey }}</span>
              </span>
            </a>
            <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
              @for (language of languages; track $index) {
                <li>
                  <a
                    class="dropdown-item"
                    [jhiActiveMenu]="language"
                    href="javascript:void(0);"
                    (click)="changeLanguage(language); collapseNavbar()"
                    >{{ language | findLanguageFromKey }}</a
                  >
                </li>
              }
            </ul>
          </a>
        </div>
      </div>
      @if (params && params == 'completeCreation') {
        <jhi-pharmacy-update></jhi-pharmacy-update>
      } @else {
        <h1 style="margin-top: 4rem" jhiTranslate="login.title" data-cy="loginTitle">Sign in</h1>
        <!-- @if (authenticationError) {
        <div class="alert alert-danger" jhiTranslate="login.messages.error.authentication" data-cy="loginError">
          <strong>Failed to sign in!</strong> Please check your credentials and try again.
        </div>
      } -->
        <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
          <div class="mb-3">
            <label class="form-label" for="username" jhiTranslate="global.form.username.label">Username</label>
            <input
              type="text"
              class="form-control"
              name="username"
              id="username"
              placeholder="{{ 'global.form.username.placeholder' | translate }}"
              formControlName="username"
              #username
              data-cy="username"
            />
            @if (loginForm.get('username')!.invalid && (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)) {
              <div>
                @if (loginForm.get('username')?.errors?.required) {
                  <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.required"
                    >Your username is required.</small
                  >
                }
              </div>
            }
          </div>
          <div class="mb-3">
            <label for="password" class="form-label" jhiTranslate="login.form.password">Password</label>
            <input
              type="password"
              class="form-control"
              name="password"
              id="password"
              placeholder="{{ 'login.form.password.placeholder' | translate }}"
              formControlName="password"
              data-cy="password"
            />
            <div *ngIf="loginForm.get('password')!.invalid && (loginForm.get('password')!.dirty || loginForm.get('password')!.touched)">
              <div *ngIf="loginForm.get('password')?.errors?.required">
                <small class="form-text text-danger" jhiTranslate="global.messages.validate.newpassword.required"
                  >Your password is required.</small
                >
              </div>
            </div>
          </div>
          <div class="form-group d-flex justify-content-between mb-3 flex-wrap">
            <div class="form-check">
              <label class="form-check-label form-label" for="rememberMe">
                <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe" />
                <span jhiTranslate="login.form.rememberme">Remember me</span>
              </label>
            </div>
            <div [ngClass]="{ 'm-auto': screenWidth < 525 }">
              <a
                class="alert-link form-label"
                routerLink="/account/reset/request"
                jhiTranslate="login.password.forgot"
                data-cy="forgetYourPasswordSelector"
                >Did you forget your password?</a
              >
            </div>
          </div>
          <button type="submit" class="col-12 btn btn-primary success-btn" jhiTranslate="login.form.button" data-cy="submit">
            Sign in
          </button>
        </form>
        <div class="d-flex justify-content-center m-3 flex-wrap">
          <label jhiTranslate="global.messages.info.register.noaccount">Vous n'avez pas encore de compte ?</label>&nbsp;<a
            class="alert-link"
            routerLink="/account/register"
            [queryParams]="params == 'pharmacist' ? { user: 'pharmacist' } : { user: 'client' }"
            jhiTranslate="global.messages.info.register.link"
            >sign in</a
          >.
        </div>
      }
    </div>
    @if (screenWidth > 1024) {
      <div *ngIf="screenWidth > 1024" class="col col-log-register" [ngStyle]="{ display: screenWidth < 1024 ? 'none' : '' }">
        @if (params === 'completeCreation') {
          <img class="login-register-image" src="../../content/images/BG Pharmacien2.png" alt="" />
        } @else {
          @if (params === 'pharmacist') {
            <img class="login-register-image" src="../../content/images/BG Pharmacien1.png" alt="" />
          } @else {
            <img class="login-register-image" src="../../content/images/client-login.png" alt="" />
          }
        }
      </div>
    }
  </div>
</div>
