<div>
  <div class="d-flex justify-content-center">
    <div class="col-lg-6 col-md-8 col-sm-10">
      <h1 jhiTranslate="login.title" data-cy="loginTitle">Sign in</h1>
      @if (authenticationError) {
        <div class="alert alert-danger" jhiTranslate="login.messages.error.authentication" data-cy="loginError">
          <strong>Failed to sign in!</strong> Please check your credentials and try again.
        </div>
      }
      <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
        <div class="mb-3">
          <label class="username-label" for="username" jhiTranslate="global.form.username.label">Username</label>
          <input
            type="text"
            class="form-control"
            name="username"
            id="username"
            placeholder="{{ 'global.form.username.placeholder' | translate }}"
            formControlName="username"
            #username
            data-cy="username"
          />
        </div>

        <div class="mb-3">
          <label for="password" jhiTranslate="login.form.password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            id="password"
            placeholder="{{ 'login.form.password.placeholder' | translate }}"
            formControlName="password"
            data-cy="password"
          />
        </div>

        <div class="form-check">
          <label class="form-check-label" for="rememberMe">
            <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe" />
            <span jhiTranslate="login.form.rememberme">Remember me</span>
          </label>
        </div>

        <button type="submit" class="btn btn-primary" jhiTranslate="login.form.button" data-cy="submit">Sign in</button>
      </form>
      <div class="mt-3 alert alert-warning">
        <a class="alert-link" routerLink="/account/reset/request" jhiTranslate="login.password.forgot" data-cy="forgetYourPasswordSelector"
          >Did you forget your password?</a
        >
      </div>

      <div class="alert alert-warning">
        <span jhiTranslate="global.messages.info.register.noaccount">You don&apos;t have an account yet?</span>
        <a class="alert-link" routerLink="/account/register" jhiTranslate="global.messages.info.register.link">Register a new account</a>
      </div>
    </div>
  </div>
</div>
