<!-- client view -->
@if (!disable) {
  @if (((account && !account.authorities.includes('ROLE_ADMIN')) || !account) && !currentUrl?.includes('admin')) {
    <nav
      *ngIf="!disable"
      [ngStyle]="{ background: !account ? '#0078C6' : '' }"
      data-cy="navbar"
      class="navbar navbar-dark navbar-expand-md custom-up-nav"
    >
      <div class="container">
        <div class="inline-block navphrase">
          <span jhiTranslate="global.menu.navphrase1">Nous vous livrons tous les jours de</span>
          <span style="font-family: 'ClashDisplay-Medium'" jhiTranslate="global.menu.navphrase2">7h00 à 23h00</span>
        </div>
        @if (account !== null) {
          <ul class="navbar-nav ms-auto top-nav">
            <li class="nav-item dropdown pointer">
              <span class="nav-link">A propos</span>
            </li>
            <span class="nav-link">|</span>
            <li class="nav-item dropdown pointer">
              <span class="nav-link">Mon compte</span>
            </li>
            <span class="nav-link">|</span>
            @if (languages && languages.length > 1) {
              <li ngbDropdown class="nav-item dropdown pointer" display="dynamic">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                  <span>
                    <fa-icon icon="flag"></fa-icon>
                    <span>{{ delfaultLang | findLanguageFromKey }}</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                  @for (language of languages; track $index) {
                    <li>
                      <a
                        class="dropdown-item"
                        [jhiActiveMenu]="language"
                        href="javascript:void(0);"
                        (click)="changeLanguage(language); collapseNavbar()"
                        >{{ language | findLanguageFromKey }}</a
                      >
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        } @else {
          <ul class="navbar-nav ms-auto d-flex align-items-center">
            <li class="nav-item">
              <img class="icon" src="../../../content/images/icons/facebook-icon.png" alt="" />
            </li>
            <li class="nav-item">
              <img class="icon" src="../../../content/images/icons/twitter-icon.png" alt="" />
            </li>
            <li class="nav-item">
              <img class="icon" src="../../../content/images/icons/instagram-icon.png" alt="" />
            </li>
            <span class="nav-link">|</span>
            @if (languages && languages.length > 1) {
              <li ngbDropdown class="nav-item dropdown pointer" display="dynamic">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                  <span>
                    <fa-icon icon="flag"></fa-icon>
                    <span>{{ delfaultLang | findLanguageFromKey }}</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                  @for (language of languages; track $index) {
                    <li>
                      <a
                        class="dropdown-item"
                        [jhiActiveMenu]="language"
                        href="javascript:void(0);"
                        (click)="changeLanguage(language); collapseNavbar()"
                        >{{ language | findLanguageFromKey }}</a
                      >
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        }
      </div>
    </nav>
    @if (account) {
      <nav *ngIf="!disable" data-cy="navbar" class="navbar navbar-dark navbar-expand-md custom-nav-down" [ngStyle]="{ top: '40px' }">
        <div class="container flex-md-column flex-lg-row navbar-container">
          <div class="col-lg-3 col-sm-12 d-flex justify-content-between">
            <a class="navbar-brand logo" routerLink="/" (click)="collapseNavbar()">
              <span class="logo-pharma"></span>
              <!-- <span jhiTranslate="global.title" class="navbar-title">Pharma</span>
      <span class="navbar-version">{{ version }}</span> -->
            </a>
            <a
              class="navbar-toggler"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="toggleNavbar()"
            >
              <fa-icon icon="bars"></fa-icon>
            </a>
          </div>
          <div
            id="navbarResponsive"
            class="justify-content-between col-lg-9 col-sm-12 flex-row navbar-collapse collapse"
            style="font-size: 14px"
            [ngbCollapse]="isNavbarCollapsed"
          >
            <div class="p-2 d-flex top-menu-location order-0 order-lg-0">
              <span class="location-icon" style="cursor: pointer" (click)="openModal(modalgeo)"></span>
              <div class="d-flex flex-column"><span>Livré à </span> <a class="alert-link">Paris</a></div>
            </div>
            <div class="p-2 search-wrap order-3 order-lg-1">
              <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
                <input
                  type="text"
                  class="form-control nav-input"
                  placeholder="Saisir enseigne pharmacie ou adresse..."
                  formControlName="searchkeys"
                  data-cy="text"
                  (keydown.enter)="onSearch()"
                />
                <span class="search-icon"></span>
              </form>
              <span class="search-icon"></span>
            </div>
            <div class="d-flex order-2 order-lg-3">
              <div class="p-2 d-flex justify-content-end align-items-center menu-client-wrap">
                <div ngbDropdown class="d-inline-block custom-dropdown">
                  <span class="avatar-icon" id="dropdownIcon" ngbDropdownToggle></span>
                  <div ngbDropdownMenu aria-labelledby="dropdownIcon">
                    <!-- Dropdown items -->
                    <a ngbDropdownItem class="dropdown-item" [routerLink]="['/profile']" routerLinkActive="router-link-active">
                      <fa-icon icon="user" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.myAccount">Mon compte</span>
                    </a>
                    <a ngbDropdownItem class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
                      <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.logout">Sign out</span>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-truncate">{{ accountFromService?.login }}</span> <a class="alert-link">Client</a>
                </div>
              </div>
              <div class="p-2 d-flex align-items-center justify-content-end">
                <div ngbDropdown class="d-inline-block custom-dropdown-notif">
                  <span class="notif-icon" id="dropdownIcon" ngbDropdownToggle>
                    <span class="notif-icon-count">{{ likeCounter }}</span>
                  </span>
                  <div ngbDropdownMenu aria-labelledby="dropdownIcon" class="dropdown-menu-end scorllable-container" #dropdownMenu>
                    <div class="nav-dropdown">
                      <span class="title-dropdown"
                        >Notifications
                        <span class="notif-icon-count-dropdown">{{ likeCounter }}</span>
                      </span>
                    </div>
                    <div *ngIf="notifications?.content?.length">
                      <div *ngFor="let notification of notifications?.content">
                        <a
                          ngbDropdownItem
                          [ngClass]="notification.read ? 'dropdown-item notification-read' : 'dropdown-item notification-unread'"
                          (click)="readNotification(notification.id)"
                          routerLinkActive="router-link-active"
                        >
                          <!--
                        <fa-icon icon="user" [fixedWidth]="true"></fa-icon> 
                      -->
                          <div class="row">
                            <div style="font-size: 15px" class="col-9">{{ notification.title }}</div>
                            <div class="col-3" style="font-size: 14px">{{ formatNotificationDate(notification.createdAt) }}</div>
                          </div>
                          <div style="font-size: 15px; white-space: break-spaces">{{ notification.body }}</div>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!notifications?.content?.length">
                      <lottie-player
                        src="../../content/images/noData.json"
                        background="transparent"
                        speed="1"
                        style="width: 300px; height: 250px; margin: auto"
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 d-flex align-items-center justify-content-end">
                <span class="cart-icon" (click)="goToCart()">
                  <span class="cart-icon-count">{{ cartItemsCount }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    } @else {
      <nav *ngIf="!disable" data-cy="navbar" class="navbar navbar-dark navbar-expand-xl navbar-expand-lg custom-nav-down">
        <div class="container">
          <a class="navbar-brand logo" routerLink="/" (click)="collapseNavbar()">
            <span class="logo-pharma"></span>
            <!-- <span jhiTranslate="global.title" class="navbar-title">Pharma</span>
      <span class="navbar-version">{{ version }}</span> -->
          </a>

          <a
            class="navbar-toggler d-xl-none"
            href="javascript:void(0);"
            data-toggle="collapse"
            data-target="#navbarResponsivee"
            aria-controls="navbarResponsivee"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="toggleNavbar()"
            [ngStyle]="{ 'margin-left': screenWidth == 1024 ? '42rem' : 'initial' }"
          >
            <fa-icon icon="bars"></fa-icon>
          </a>
          <div
            class="col-lg-8 col-md-8 landing-bar navbar-collapse collapse"
            id="navbarResponsivee"
            [ngbCollapse]="isNavbarCollapsed"
            style="font-size: 14px"
          >
            <ul class="navbar-nav ms-auto col-md-12 d-flex align-items-start">
              <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12 d-lg-flex justify-content-lg-end">
                <li class="nav-item dropdown pointer">
                  <span jhiTranslate="global.menu.home" class="nav-link">Accueil</span>
                </li>
                <li class="nav-item dropdown pointer">
                  <span jhiTranslate="global.menu.order" class="nav-link">Commander</span>
                </li>
                <li
                  ngbDropdown
                  class="nav-item dropdown pointer"
                  display="dynamic"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    style="color: black"
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    id="admin-menu"
                    data-cy="adminMenu"
                  >
                    <span>
                      <span jhiTranslate="global.menu.become a partner">Devenir Partenaire</span>
                    </span>
                  </a>
                  <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        routerLink="/login"
                        [queryParams]="{ user: 'pharmacist' }"
                        routerLinkActive="active"
                        (click)="collapseNavbar()"
                      >
                        <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
                        <span jhiTranslate="global.menu.pharmacist">pharmacist</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" routerLinkActive="active" (click)="collapseNavbar()">
                        <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
                        <span jhiTranslate="global.menu.deliveryman">Deliveryman</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </div>
              <div class="d-flex flex-row align-items-end col-xl-4 col-lg-5 col-xs-12 justify-content-lg-end">
                <li class="btn-connect-header">
                  <!-- Show only on large screens -->
                  <button routerLink="/login" jhiTranslate="global.menu.account.login" class="btn btn-primary login">Se connecter</button>
                </li>
                <li class="nav-item btn-register-header">
                  <!-- Hide on large screens -->
                  <button
                    routerLink="/account/register"
                    [queryParams]="{ user: 'client' }"
                    jhiTranslate="global.menu.account.register"
                    class="btn btn-outline-primary register"
                  >
                    S'inscrire
                  </button>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    }
  } @else {
    <!-- code to be used with admin -->
    <nav *ngIf="!disable" data-cy="navbar" class="navbar navbar-dark navbar-expand-md bg-primary" style="position: unset">
      <div class="container-fluid">
        <a class="navbar-brand logo" routerLink="/admin/home" (click)="collapseNavbar()">
          <span class="logo-img"></span>
          <span jhiTranslate="global.title" class="navbar-title">Pharma</span>
          <span class="navbar-version">{{ version }}</span>
        </a>
        <a
          class="navbar-toggler d-lg-none"
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="toggleNavbar()"
        >
          <fa-icon icon="bars"></fa-icon>
        </a>
        <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a class="nav-link" routerLink="/admin/home" (click)="collapseNavbar()">
                <span>
                  <fa-icon icon="home"></fa-icon>
                  <span jhiTranslate="global.menu.home">Home</span>
                </span>
              </a>
            </li>
            <!-- jhipster-needle-add-element-to-menu - JHipster will add new menu items here -->
            @if (account !== null) {
              <li
                ngbDropdown
                class="nav-item dropdown pointer"
                display="dynamic"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu" data-cy="entity">
                  <span>
                    <fa-icon icon="th-list"></fa-icon>
                    <span jhiTranslate="global.menu.entities.main">Entities</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/application-user"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.applicationUser">Application User</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/beneficiary"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.beneficiary">Beneficiary</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/pharmacy"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.pharmacy">Pharmacy</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/schedule"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.schedule">Schedule</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/product"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.product">Product</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/service"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.service">Service</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/service-period"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.servicePeriod">Service Period</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/category"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.category">Category</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/pack"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.pack">Pack</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/purchase"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.purchase">Purchase</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/order-status"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.orderStatus">Order Status</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/payment"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.payment">Payment</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/payment-distribution"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.paymentDistribution">Payment Distribution</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/delivery"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.delivery">Delivery</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/delivery-partner"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.deliveryPartner">Delivery Partner</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/courier"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.courier">Courier</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/region"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.region">Region</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/message"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.message">Message</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/attachment"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.attachment">Attachment</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/conversation"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.conversation">Conversation</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/notification"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.notification">Notification</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/promotion"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.promotion">Promotion</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/sub-category"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.subCategory">Sub Category</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/add-authority-request"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.addAuthorityRequest">Add Authority Request</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/stock-item"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="collapseNavbar()"
                    >
                      <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.entities.stockItem">Stock Item</span>
                    </a>
                  </li>
                  <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
                </ul>
              </li>
            }
            <li
              *jhiHasAnyAuthority="'ROLE_ADMIN'"
              ngbDropdown
              class="nav-item dropdown pointer"
              display="dynamic"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu" data-cy="adminMenu">
                <span>
                  <fa-icon icon="users-cog"></fa-icon>
                  <span jhiTranslate="global.menu.admin.main">Administration</span>
                </span>
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
                <li>
                  <a class="dropdown-item" routerLink="/admin/user-management" routerLinkActive="active" (click)="collapseNavbar()">
                    <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
                    <span jhiTranslate="global.menu.admin.userManagement">User management</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/admin/metrics" routerLinkActive="active" (click)="collapseNavbar()">
                    <fa-icon icon="tachometer-alt" [fixedWidth]="true"></fa-icon>
                    <span jhiTranslate="global.menu.admin.metrics">Metrics</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/admin/health" routerLinkActive="active" (click)="collapseNavbar()">
                    <fa-icon icon="heart" [fixedWidth]="true"></fa-icon>
                    <span jhiTranslate="global.menu.admin.health">Health</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/admin/configuration" routerLinkActive="active" (click)="collapseNavbar()">
                    <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
                    <span jhiTranslate="global.menu.admin.configuration">Configuration</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/admin/logs" routerLinkActive="active" (click)="collapseNavbar()">
                    <fa-icon icon="tasks" [fixedWidth]="true"></fa-icon>
                    <span jhiTranslate="global.menu.admin.logs">Logs</span>
                  </a>
                </li>
                @if (openAPIEnabled) {
                  <li>
                    <a class="dropdown-item" routerLink="/admin/docs" routerLinkActive="active" (click)="collapseNavbar()">
                      <fa-icon icon="book" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.admin.apidocs">API</span>
                    </a>
                  </li>
                }
                <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
              </ul>
            </li>
            @if (languages && languages.length > 1) {
              <li ngbDropdown class="nav-item dropdown pointer" display="dynamic">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                  <span>
                    <fa-icon icon="flag"></fa-icon>
                    <span jhiTranslate="global.menu.language">Language</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                  @for (language of languages; track $index) {
                    <li>
                      <a
                        class="dropdown-item"
                        [jhiActiveMenu]="language"
                        href="javascript:void(0);"
                        (click)="changeLanguage(language); collapseNavbar()"
                        >{{ language | findLanguageFromKey }}</a
                      >
                    </li>
                  }
                </ul>
              </li>
            }
            <li
              ngbDropdown
              class="nav-item dropdown pointer"
              display="dynamic"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu" data-cy="accountMenu">
                @if (!account?.imageUrl) {
                  <span>
                    <fa-icon icon="user"></fa-icon>
                    <span jhiTranslate="global.menu.account.main">Account</span>
                  </span>
                } @else {
                  <span>
                    <img [src]="account!.imageUrl" class="profile-image rounded-circle" alt="Avatar" />
                  </span>
                }
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
                @if (account !== null) {
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/account/settings"
                      routerLinkActive="active"
                      (click)="collapseNavbar()"
                      data-cy="settings"
                    >
                      <fa-icon icon="wrench" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.settings">Settings</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/account/password"
                      routerLinkActive="active"
                      (click)="collapseNavbar()"
                      data-cy="passwordItem"
                    >
                      <fa-icon icon="lock" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.password">Password</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
                      <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.logout">Sign out</span>
                    </a>
                  </li>
                } @else {
                  <li>
                    <a class="dropdown-item" routerLink="/admin/signin" id="login" data-cy="login">
                      <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.login">Sign in</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/account/register"
                      routerLinkActive="active"
                      (click)="collapseNavbar()"
                      data-cy="register"
                    >
                      <fa-icon icon="user-plus" [fixedWidth]="true"></fa-icon>
                      <span jhiTranslate="global.menu.account.register">Register</span>
                    </a>
                  </li>
                }
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  }
}

<ng-template #modalgeo let-modalgeo>
  <div class="modal-product">
    <div class="modal-header modalgeoAdresse">
      <div class="col-11 d-flex justify-content-center">
        <img src="../../content/images/map-modal-img.png" alt="" />
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalgeo.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex flex-column align-items-center">
        <h3>Liste des adresses</h3>
        <div class="address-list-container">
          <span *ngIf="!addresses.length">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 250px; height: 200px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </span>
          <div class="col-12" *ngFor="let address of addresses">
            <div class="d-flex align-items-center justify-content-between cart-item">
              <div class="address-checkbox">
                <input
                  type="radio"
                  name="address"
                  [value]="address.id"
                  [checked]="address.id === selectedAddressId"
                  (change)="onAddressSelection(address)"
                />
              </div>
              <div class="address-info">
                <label for="maison" class="address-label">{{ address.name }}</label>
                <div class="address-detail">
                  {{ address.streetNumber }} {{ address.streetName }}, {{ address.city }}, {{ address.state }}, {{ address.postalCode }},
                  {{ address.country }}
                </div>
              </div>
              <div class="address-actions">
                <div class="btn-wrap">
                  <button class="btn-command btn-edit" (click)="onAddressChange(address); modalgeo.close()"></button>
                  <button class="btn-command btn-delete" (click)="deleteAdress(address.id)"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex flex-column align-items-center mt-3 down-input">
          <span class="position" (click)="OpenAddressModal(); modalgeo.close()" style="cursor: pointer"
            >Ajouter une nouvelle adresse de livraison</span
          >
        </div>
        <button class="btn btn-success mt-3 saveAdresse" (click)="onSave(); modalgeo.close()">ENREGISTRER</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalgeoTow let-modalgeoTow>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center">
        <img src="../../content/images/map-modal-img.png" alt="" />
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalgeoTow.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus [formGroup]="adresseForm">
      <div class="d-flex flex-column align-items-center">
        <h3>Modifier adresse de livraison</h3>
        <div class="col-12">
          <auto-complete
            (placeSelected)="onPlaceSelected($event); modalgeoTow.dismiss('Cross click')"
            [optionalInput]="address"
          ></auto-complete>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div>
            <label for="">Etage / Appartement</label>
            <input type="text" placeholder="Etage / Appartement" formControlName="appartmentNumber" class="form-control mb-3" />
          </div>
          <div>
            <label for="">Nom adresse</label>
            <input type="text" placeholder="Ex: Maison / Bureau" formControlName="name" class="form-control mb-3" />
          </div>
        </div>

        <div class="d-flex justify-content-between w-100 down-input">
          <!--
           <span class="place" (click)="useCurrentLocation()" style="cursor: pointer">Utiliser ma position</span>
          <span class="position" (click)="openMapInHome(); modalgeoTow.dismiss('Cross click')" style="cursor: pointer"
            >Définir sur la carte</span>
          -->
        </div>
        <button class="btn btn-success mt-3 saveAdresse" (click)="updateAdresse(); modalgeoTow.close()">ENREGISTRER</button>
        <div class="col-12 d-flex flex-column align-items-start down-input"></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalgeoAdd let-modalgeoAdd>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center">
      <img src="../../content/images/map-modal-img.png" alt="" />
    </div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalgeoAdd.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus [formGroup]="adresseForm">
    <div class="d-flex flex-column align-items-center">
      <h3>Ajouter une adresse de livraison</h3>
      <span class="mb-4">
        Pour améliorer votre expérience client et réduire le temps de livraison, veuillez indiquer l'adresse de livraison
      </span>
      <div class="col-12">
        <auto-complete (placeSelected)="onPlaceSelected($event)" [optionalInput]="address"></auto-complete>
      </div>
      <div class="d-flex justify-content-between w-100">
        <div>
          <label for="">Etage / Appartement</label>
          <input type="text" placeholder="Etage / Appartement" formControlName="appartmentNumber" class="form-control mb-3" />
        </div>
        <div>
          <label for="">Nom adresse</label>
          <input type="text" placeholder="Ex: Maison / Bureau" formControlName="name" class="form-control mb-3" />
        </div>
      </div>
      <div class="d-flex justify-content-between w-100 down-input">
        <span class="place" (click)="useCurrentLocation(); modalgeoAdd.dismiss('Cross click')" style="cursor: pointer"
          >Utiliser ma position</span
        >
        <span class="position" (click)="openMapInHome(); modalgeoAdd.dismiss('Cross click')" style="cursor: pointer"
          >Définir sur la carte</span
        >
      </div>
      <button class="btn btn-success mt-3 saveAdresse" (click)="addAdressToclient(); modalgeoAdd.close()">ENREGISTRER</button>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>

<ng-template #modalMaps let-modalMaps>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalMaps.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex flex-column align-items-center">
        <h3>Choisir l'emplacement sur la carte</h3>
        <google-map #mapComponent height="400px" [center]="center" [zoom]="zoom">
          <map-marker
            (mapDragend)="logCenter($event)"
            [position]="markerPosition"
            [options]="markerOptions"
            [draggable]="true"
          ></map-marker>
        </google-map>
        <div class="d-flex justify-content-between w-100 map-container"></div>

        <div class="d-flex justify-content-between w-100 down-input"></div>
        <button class="btn btn-success mt-3 closeAdresse" (click)="modalMaps.dismiss('Cross click')">ANNULER</button>
        <button class="btn btn-success mt-3 saveAdresse" (click)="reOpenAddressModal()">VALIDER</button>
        <div class="col-12 d-flex flex-column align-items-start down-input"></div>
      </div>
    </div>
  </div>
</ng-template>
