@if (!disable) {
  <div class="footer mt-5">
    <div class="container">
      <div [hidden]="currentUrl?.includes('/home')" class="row d-flex align-items-center p-3 mt-3">
        @if (!account?.authorities.includes('ROLE_USER')) {
          <div class="col-4 d-flex flex-column description">
            <span class="logo-pharma-dark"></span>
            <p jhiTranslate="global.menu.footer.description">
              La plateforme PharmaGo vous permet de commander vos médicaments et produits parapharmaceutique de chez plus que 200
              partenaires.
            </p>
            <ul class="navbar-nav d-flex flex-row flex-start">
              <li class="nav-item social">
                <img class="icon" src="../../../content/images/icons/facebook-icon.png" alt="" />
              </li>
              <li class="nav-item social">
                <img class="icon" src="../../../content/images/icons/twitter-icon.png" alt="" />
              </li>
              <li class="nav-item social">
                <img class="icon" src="../../../content/images/icons/instagram-icon.png" alt="" />
              </li>
            </ul>
          </div>
          <div class="col-5 d-flex flex-column align-items-left justify-content-center links">
            <h4 class="pb-1 footer-title" jhiTranslate="global.menu.footer.links">Liens Utiles</h4>
            <ul class="d-flex flex-wrap">
              <div class="col-md-5">
                <li class="nav-item"><a jhiTranslate="global.menu.home" href="#">home</a></li>
                <li class="nav-item"><a jhiTranslate="global.menu.order" href="#">Commander</a></li>
                <li class="nav-item"><a href="#">Services</a></li>
                <li class="nav-item"><a jhiTranslate="global.menu.become a partner" href="#">Devenir partenaire</a></li>
              </div>
              <div class="col-md-7">
                <li class="nav-item">
                  <a jhiTranslate="global.menu.footer.legalnotice" routerLink="/politique-de-confidentialite" target="_blank"
                    >Politique de Confidentialité</a
                  >
                </li>
                <li class="nav-item"><a jhiTranslate="global.form.GTU.title" routerLink="/CGU" target="_blank">CGU</a></li>
                <li class="nav-item"><a jhiTranslate="global.form.GTS.title" routerLink="/CGV" target="_blank">CGV</a></li>
              </div>
            </ul>
          </div>
          <div class="col-3 d-flex flex-column align-items-left justify-content-center contacts">
            <h4 class="pb-1 footer-title">Contact</h4>
            <ul class="d-flex flex-column">
              <li class="nav-item email">contact&#64;pharma-go.fr</li>
              <li class="nav-item phone">06 59 28 36 56</li>
              <li class="nav-item address">101 rue de Sèvres 75006 Paris - France</li>
            </ul>
          </div>
          <hr />
        }
        <div class="footer-bottom">
          <span
            >© 2024 <span class="pharma">PharmaGo</span> .
            <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
            <span class="underline">KCA Dev Services.</span>
          </span>
        </div>
      </div>
    </div>
  </div>
}
