<div class="politique-de-confidentialite">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="privacyPolicy.title"></h1>
  </div>
  <div class="content">
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.introduction.title">1. Introduction</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.introduction.content">
        La présente politique de confidentialité décrit comment PHARMAGO (ci-après « la Plateforme »), exploitée par PHARMAGO, collecte,
        utilise, conserve et protège les données personnelles des utilisateurs (ci-après « les Utilisateurs »). Nous nous engageons à
        respecter la confidentialité des données et à protéger les informations personnelles de nos Utilisateurs conformément au Règlement
        Général sur la Protection des Données (RGPD).
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.dataCollected.title">2. Données Collectées</h2>
      <p jhiTranslate="privacyPolicy.dataCollected.description">Nous collectons les données personnelles suivantes :</p>
      <ul>
        <li jhiTranslate="privacyPolicy.dataCollected.items.identificationInfo">
          Informations d'identification : nom, prénom, adresse email, numéro de téléphone.
        </li>
        <li jhiTranslate="privacyPolicy.dataCollected.items.loginInfo">Informations de connexion : identifiant et mot de passe.</li>
        <li jhiTranslate="privacyPolicy.dataCollected.items.paymentInfo">
          Informations de paiement : données bancaires pour les transactions (via des prestataires de paiement sécurisés).
        </li>
        <li jhiTranslate="privacyPolicy.dataCollected.items.deliveryInfo">
          Informations de livraison : adresse de livraison, instructions spécifiques.
        </li>
        <li jhiTranslate="privacyPolicy.dataCollected.items.healthInfo">
          Données de santé : uniquement les informations nécessaires pour la vente de médicaments, sous réserve du consentement explicite de
          l'Utilisateur.
        </li>
        <li jhiTranslate="privacyPolicy.dataCollected.items.browsingData">
          Données de navigation : adresse IP, type de navigateur, pages visitées, durée de visite.
        </li>
      </ul>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.dataUsage.title">3. Utilisation des Données</h2>
      <p jhiTranslate="privacyPolicy.dataUsage.description">Les données collectées sont utilisées pour :</p>
      <ul>
        <li jhiTranslate="privacyPolicy.dataUsage.items.orderManagement">
          Gérer les commandes et les livraisons : traitement des commandes, expédition des produits, communication avec les Utilisateurs.
        </li>
        <li jhiTranslate="privacyPolicy.dataUsage.items.serviceImprovement">
          Améliorer nos services : analyse de l'utilisation de la Plateforme, enquêtes de satisfaction.
        </li>
        <li jhiTranslate="privacyPolicy.dataUsage.items.platformSecurity">
          Assurer la sécurité de la Plateforme : prévention des fraudes, détection des activités suspectes.
        </li>
        <li jhiTranslate="privacyPolicy.dataUsage.items.marketingCommunication">
          Communication marketing : envoi de newsletters et d'offres promotionnelles, sous réserve du consentement de l'Utilisateur.
        </li>
      </ul>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.dataSharing.title">4. Partage des Données</h2>
      <p jhiTranslate="privacyPolicy.dataSharing.description">Nous partageons les données personnelles avec :</p>
      <ul>
        <li jhiTranslate="privacyPolicy.dataSharing.items.deliveryPartners">
          Partenaires de livraison : pour assurer la livraison des produits.
        </li>
        <li jhiTranslate="privacyPolicy.dataSharing.items.serviceProviders">
          Prestataires de services : fournisseurs de services informatiques, plateformes de paiement, services de marketing.
        </li>
        <li jhiTranslate="privacyPolicy.dataSharing.items.legalAuthorities">
          Autorités légales : lorsque cela est requis par la loi ou pour répondre à des demandes légales.
        </li>
      </ul>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.dataRetention.title">5. Conservation des Données</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.dataRetention.content">
        Les données personnelles sont conservées pour la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été
        collectées, dans le respect des obligations légales et réglementaires. Les données de santé sont conservées conformément aux
        exigences légales spécifiques applicables.
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.userRights.title">6. Droits des Utilisateurs</h2>
      <p jhiTranslate="privacyPolicy.userRights.description">Conformément au RGPD, les Utilisateurs disposent des droits suivants :</p>
      <ul>
        <li jhiTranslate="privacyPolicy.userRights.items.rightOfAccess">
          Droit d'accès : obtenir des informations sur les données personnelles détenues par la Plateforme.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightOfRectification">
          Droit de rectification : demander la correction des données inexactes ou incomplètes.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightOfErasure">
          Droit à l'effacement : demander la suppression des données personnelles, sous réserve des obligations légales de conservation.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightToRestrictProcessing">
          Droit à la limitation du traitement : demander la restriction du traitement des données dans certains cas.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightToDataPortability">
          Droit à la portabilité : recevoir les données personnelles dans un format structuré et couramment utilisé, ou demander leur
          transfert à un autre responsable de traitement.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightToObject">
          Droit d'opposition : s'opposer au traitement des données pour des motifs légitimes ou à des fins de prospection commerciale.
        </li>
        <li jhiTranslate="privacyPolicy.userRights.items.rightToWithdrawConsent">
          Droit de retirer son consentement : retirer à tout moment son consentement pour le traitement des données basé sur le
          consentement.
        </li>
      </ul>
      <p jhiTranslate="privacyPolicy.userRights.contact">
        Pour exercer ces droits, les Utilisateurs peuvent nous contacter à l'adresse suivante : [contact&#64;pharma-go.fr] ou par courrier à
        [101 rue de Sèvres 75279 Paris - France].
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.dataSecurity.title">7. Sécurité des Données</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.dataSecurity.content">
        Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger les données personnelles
        contre la destruction, la perte, l'altération, la divulgation ou l'accès non autorisés.
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.cookies.title">8. Cookies et Technologies Similaires</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.cookies.content">
        La Plateforme utilise des cookies pour améliorer l'expérience utilisateur, analyser l'utilisation de nos services et personnaliser
        les contenus. Les Utilisateurs peuvent gérer les préférences de cookies via les paramètres de leur navigateur.
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.policyChanges.title">9. Modifications de la Politique de Confidentialité</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.policyChanges.content">
        Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment. Les modifications seront publiées
        sur cette page avec une mise à jour de la date d'entrée en vigueur. Nous encourageons les Utilisateurs à consulter régulièrement
        cette page pour se tenir informés des mises à jour.
      </p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicy.contact.title">10. Contact</h2>
      <p class="section-text" jhiTranslate="privacyPolicy.contact.content">
        Pour toute question ou réclamation, vous pouvez nous contacter à l'adresse suivante : [contact&#64;pharma-go.fr] ou par téléphone au
        [06 59 28 36 56].
      </p>
    </div>
  </div>
</div>
<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span
        >© 2024 <span class="pharma">PharmaGo</span> .
        <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
