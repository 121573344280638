<div class="card product-card" style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer">
  <img [src]="imageUrl + product?.product?.productImage + '/large'" class="prod-img-top" alt="Image" />
  <div class="card-body" style="display: flex; flex-direction: column">
    <h5 class="card-title">{{ product?.product?.title }}</h5>
    <div class="d-flex flex-row mb-3">
      <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
      <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
      <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
      <img src="../../content/images/icons/star-icon.svg" alt="" />
      <img src="../../content/images/icons/star-icon.svg" alt="" />
    </div>
    <a class="btn btn-light d-flex delivery">EN PROMO </a>
    <a class="btn btn-light d-flex likeBtn" (click)="toggleLike()">
      <img [src]="likeImagePath" alt="Like" />
    </a>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <div class="col-5 d-flex align-items-center flex-column">
        <h3 class="price" style="font-size: 15px; text-decoration: line-through; font-family: 'ClashDisplay-Regular'">
          {{ product.price.toFixed(2) }}€
        </h3>
        <h1 class="price m-auto">{{ (product.price * (1 - product.discount / 100)).toFixed(2) }}€</h1>
      </div>
      <div class="col-7 d-flex justify-content-end"><a class="btn order">Ajouter au panier</a></div>
    </div>
  </div>
</div>
