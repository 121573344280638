import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from '../orders.service';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CartService } from '../../cart/cart.service';
import { PaginatorModule } from 'primeng/paginator';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-orders',
  standalone: true,
  imports: [NgbModule, CommonModule, NgxSkeletonLoaderModule, PaginatorModule],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrdersComponent implements OnInit {
  purshases: any;
  contentLoaded = false;
  first: number = 0;
  rows: number = 1;
  totalRecords!: any;

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private cartService: CartService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPurshases();
  }

  hasInProgressPurchases: boolean = false;
  hasCreatedWithPrescription: boolean = false;
  hasDraft: boolean = false;
  hasPackagingReady: boolean = false;
  hasRecoveredOrCanceled: boolean = false;
  hasDelivering: boolean = false;
  getPurshases() {
    this.orderService.getPurchases(this.first).subscribe({
      next: res => {
        this.totalRecords = res.totalPages;
        this.purshases = res.content;
        this.hasInProgressPurchases = this.purshases.some(
          (purchase: any) => purchase.lastStatus.name === 'IN_PROGRESS' || purchase.lastStatus.name === 'PAYMENT_INSTANCE',
        );
        this.hasCreatedWithPrescription = this.purshases.some((purchase: any) => purchase.lastStatus.name === 'CREATED_WITH_PRESCRIPTION');
        this.hasDelivering = this.purshases.some(
          (purchase: any) => purchase.lastStatus.name === 'RECOVERING' || purchase.lastStatus.name === 'DELIVERING',
        );

        this.hasDraft = this.purshases.some((purchase: any) => purchase.lastStatus.name === 'DRAFT');
        this.hasPackagingReady = this.purshases.some(
          (purchase: any) => purchase.lastStatus.name === 'PACKAGING' || purchase.lastStatus.name === 'READY',
        );
        this.hasRecoveredOrCanceled = this.purshases.some((purchase: any) =>
          ['RECOVERED', 'RETRACTED', 'CANCELED', 'REJECTED', 'LOST'].includes(purchase.lastStatus.name),
        );
      },
      error: err => {
        console.log(err);
      },
    });
  }
  onDeleteClick(purshase_id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet commande ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deletePurshases(purshase_id);
      }
    });
  }
  deletePurshases(purshase_id: any) {
    this.orderService.deletePurchases(purshase_id).subscribe({
      next: res => {
        this.getPurshases();
      },
      error: err => {
        console.log(err);
      },
    });
  }
  initiatePaymentProxy(purchaseId: number) {
    const headers = {
      'X-Client-Type': 'web',
    };
    this.cartService.initiatePaymentProxy(purchaseId, headers).subscribe({
      next: (redirectUrl: any) => {
        if (redirectUrl && redirectUrl.url) {
          window.location.href = redirectUrl.url;
        } else {
          console.error('Lien de redirection non trouvé dans la réponse.');
        }
      },
      error: err => {
        console.error("Une erreur est survenue lors de l'appel explicite de paiement : ", err);
      },
    });
  }

  navigateToPurchaseDetails(purchase_id: any) {
    this.router.navigate(['/order-details', { purchase_id: purchase_id }]);
  }
  onPageChange(event: any) {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getPurshases();
  }
}
