import { Component } from '@angular/core';

@Component({
  selector: 'jhi-payment-details',
  standalone: true,
  imports: [],
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.scss',
})
export class PaymentDetailsComponent {}
