<div class="row mt-5 mb-4">
  <div class="col-sm-12 col-md-5">
    <h2 class="lead" style="color: #0078c6">Détails Commande</h2>
  </div>
  <div class="col-sm-12 col-md-7 d-sm-flex justify-content-md-end flex-column flex-sm-row mt-3">
    <span class="command-id">ID : {{ purchaseDetails?.id }}</span>
    <span class="badge bg-new">{{ getUserStatus(purchaseDetails?.lastStatus?.name) }}</span>
  </div>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #navCommandDetails="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="6">
        <a ngbNavLink>Frais</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Titre</th>
                  <th scope="col">Prix Unitaire</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Prix TTC</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Produits/Médicaments</td>
                  <td>{{ purchaseDetails?.productsAmount.toFixed(2) }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.productsAmount.toFixed(2) }}€</span>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-delete"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Livraison</td>
                  <td>{{ purchaseDetails?.deliveryFee }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.deliveryFee }}€</span>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <!--<button class="btn-command btn-edit"></button>-->
                      <button class="btn-command btn-delete"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td style="background-color: #fbfbfb"><span class="total">Totale</span></td>
                  <td style="background-color: #fbfbfb">
                    <span class="total-price">{{ purchaseDetails?.totalAmount.toFixed(2) }}€</span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink>Détails</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <form action="">
                    <div class="mb-3 row">
                      <div class="col-sm-6">
                        <label for="firstName">Prénom</label>
                        <input type="text" name="firstName" [(ngModel)]="account.firstName" id="firstName" />
                      </div>
                      <div class="col-sm-6">
                        <label for="lastName">Nom</label>
                        <input type="text" name="lastName" id="lastName" [(ngModel)]="account.lastName" />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-6">
                        <label for="tel">Num Tél</label>
                        <input type="tel" name="tel" id="tel" [(ngModel)]="account.applicationUser.phoneNumber" />
                      </div>
                      <div class="col-sm-6">
                        <label for="mail">Mail</label>
                        <input type="email" name="mail" id="mail" [(ngModel)]="account.email" />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-12">
                        <label for="address">Adresse</label>
                        <input type="text" name="address" id="address" [(ngModel)]="account.applicationUser.deliveryAddress" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 mt-3 mt-md-0">
              <div class="card mb-3" *ngIf="fileUrlsFromOvh.length">
                <div class="card-body">
                  <div class="command-summary">
                    <div class="d-flex flex-column">
                      <div class="">
                        <label for="ordonnance">Ordonnance</label>
                        <div class="documents">
                          <div class="document" *ngFor="let fileUrl of fileUrlsFromOvh; let i = index">
                            <img [src]="fileUrl" alt="Ordonnance Image" class="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="command-summary">
                    <div class="d-flex flex-column">
                      <div class="summary">
                        <div class="summary-item">
                          <p>
                            Pharmacie : <b>{{ purchaseDetails?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName }}</b>
                          </p>
                        </div>
                        <div class="summary-item">
                          <p>
                            Num commande : <b>{{ numCommande }}</b>
                          </p>
                        </div>
                        <div class="summary-item">
                          <p>Type de livraison : <b>Livraison à domicile</b></p>
                        </div>
                        <div class="summary-item summary-total">
                          <p>
                            Totale de la commande : <span class="total-price">{{ purchaseDetails?.totalAmount.toFixed(2) }}€</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  <button class="btn btn-confirm btn-block mt-3" (click)="updateProfile()">METTRE À JOUR</button> -->
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <a ngbNavLink>Notification</a>
        <ng-template ngbNavContent>
          <div class="chat-wrapper">
            <div class="chat-box">
              <div class="msges">
                <div class="msg_history" #msgHistory>
                  <div
                    *ngFor="let msg of messages"
                    [ngClass]="{
                      incoming_msg: msg.sender !== 'Vous',
                      outgoing_msg: msg.sender === 'Vous',
                      'justify-content-end': msg.sender === 'Vous'
                    }"
                    class="d-flex"
                  >
                    <div class="incoming_msg_img" *ngIf="msg.sender !== 'Vous'">
                      <img src="/content/images/pharmacie-1.png" alt="pharmacy" />
                    </div>
                    <div class="received_msg" *ngIf="msg.sender !== 'Vous'">
                      <div class="received_withd_msg">
                        <p>{{ msg.sender }} :</p>
                        <p>{{ msg.text }}</p>
                        <span class="time_date">{{ msg.time }}</span>
                      </div>
                    </div>
                    <div class="sent_msg" *ngIf="msg.sender === 'Vous'">
                      <p>{{ msg.sender }} :</p>
                      <p>{{ msg.text }}</p>
                      <span class="time_date">{{ msg.time }}</span>
                    </div>
                    <div class="sent_msg_img" *ngIf="msg.sender === 'Vous'">
                      <img src="/content/images/user-2.png" alt="user" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-input-wrapper">
              <input type="text" [(ngModel)]="message" placeholder="Type your message here..." (keydown.enter)="sendMessage()" />
              <button class="send-msge" (click)="sendMessage()"></button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navCommandDetails" class="mt-2"></div>
  </div>
</div>
