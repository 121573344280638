import { NgModule } from '@angular/core';
import { BrowserPlatformLocation, CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import FindLanguageFromKeyPipe from './language/find-language-from-key.pipe';
import TranslateDirective from './language/translate.directive';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { PharmacyCardComponent } from '../pharmacy-card/pharmacy-card.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { RouterModule } from '@angular/router';
import { AutoCompleteComponent } from './autoComplete/autoCompleteComponent';
import { ApiService } from '../home/service/map.service';
import { LoaderComponent } from './loader/loader.component';
/**
 * Application wide Module
 */
@NgModule({
  imports: [
    AlertComponent,
    FormsModule,
    NgbDropdownModule,
    AlertErrorComponent,
    FindLanguageFromKeyPipe,
    TranslateDirective,
    NgbTooltipModule,
    LoaderComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    LoaderComponent,
    NgbModule,
    FontAwesomeModule,
    AlertComponent,
    AlertErrorComponent,
    TranslateModule,
    FindLanguageFromKeyPipe,
    TranslateDirective,
    PharmacyCardComponent,
    ProductCardComponent,
    AutoCompleteComponent,
  ],
  providers: [ApiService],
  declarations: [PharmacyCardComponent, ProductCardComponent, AutoCompleteComponent],
})
export default class SharedModule {}
