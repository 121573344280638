<div class="row mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #0078c6">Paramètre du compte</h2>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-xl-8">
    <ul ngbNav #nav="ngbNav" class="nav nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Modifier profil</a>
        <ng-template ngbNavContent>
          <!-- Contenu pour Modifier profil -->
          <div class="profile-form">
            <form action="">
              <div class="mb-3 row">
                <div class="col-sm-6">
                  <label for="mail">Email</label>
                  <input [(ngModel)]="account.email" type="email" name="mail" id="mail" />
                </div>
                <div class="col-sm-6">
                  <label for="tel">Numéro de téléphone</label>
                  <input [(ngModel)]="account.applicationUser.phoneNumber" type="tel" name="tel" id="tel" />
                </div>
              </div>
              <div class="profile-btn-wrapper mt-5">
                <button type="submit" class="btn btn-maj" (click)="updateAccount()">
                  <img src="/content/images/icons/edit.svg" alt="" /> Mettre à jour
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Modifier mot de passe</a>
        <ng-template ngbNavContent>
          <!-- Contenu pour Modifier mot de passe -->
          <div class="profile-formPassword">
            <form action="">
              <div class="mb-3 row"></div>
              <div class="mb-3 row">
                <div class="col-sm-8">
                  <label for="currentPassword">Mot de passe actuel</label>
                  <div class="styleInput">
                    <input
                      [(ngModel)]="changePasswordForm.currentPassword"
                      [type]="showCurrentPassword ? 'text' : 'password'"
                      name="currentPassword"
                      id="currentPassword"
                      class="form-control"
                      placeholder="Mot de passe actuel"
                    />
                    <div class="input-group-text" (click)="toggleShowPassword('current')" style="border-radius: 0 7px 7px 0">
                      <i class="fa" [ngClass]="{ eye: !showCurrentPassword, 'eye-slash': showCurrentPassword }"></i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <label for="newPassword">Nouveau mot de passe</label>
                  <div class="styleInput">
                    <input
                      [(ngModel)]="changePasswordForm.newPassword"
                      [type]="showNewPassword ? 'text' : 'password'"
                      name="newPassword"
                      id="newPassword"
                      class="form-control"
                      placeholder="Nouveau mot de passe"
                    />
                    <span class="input-group-text" (click)="toggleShowPassword('new')" style="border-radius: 0 7px 7px 0">
                      <i class="fa" [ngClass]="{ eye: !showNewPassword, 'eye-slash': showNewPassword }"></i>
                    </span>
                  </div>
                </div>
                <div class="col-sm-8">
                  <label for="confirmPassword">Confirmer mot de passe</label>
                  <div class="styleInput">
                    <input
                      [(ngModel)]="changePasswordForm.confirmPassword"
                      [type]="showConfirmPassword ? 'text' : 'password'"
                      name="confirmPassword"
                      id="confirmPassword"
                      class="form-control"
                      placeholder="Confirmer mot de passe"
                    />
                    <span class="input-group-text" (click)="toggleShowPassword('confirm')" style="border-radius: 0 7px 7px 0">
                      <i class="fa" [ngClass]="{ eye: !showConfirmPassword, 'eye-slash': showConfirmPassword }"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="profile-btn-wrapper mt-5">
                <button type="submit" class="btn btn-maj" (click)="changePassword()">
                  <img src="/content/images/icons/edit.svg" alt="" /> Mettre à jour
                </button>
              </div>
              <div class="profile-btn-wrapper mt-5">
                <button type="submit" class="btn btn-Delete" (click)="openModal(modalDeleteAccount)">
                  <img src="/content/images/icons/delete.svg" alt="" /> Supprimer Account
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-3"></div>
  </div>
</div>

<ng-template #modalDeleteAccount let-modalDeleteAccount>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalDeleteAccount.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModal">Vous êtes sur le point de supprimer votre compte utilisateur.</p>
      <span class="mb-4 productExist">
        Votre compte sera définitivement supprimé après 30 jours d'inactivité à compter de la date de confirmation de cette action. Pour
        récupérer votre compte durant cette période, il vous suffit de vous reconnecter.
      </span>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="deleteAccountClient(); modalDeleteAccount.close('clearCart')">
          CONFIRMER
        </button>
      </div>
      <div class="d-flex align-items-center down-input">
        <span style="cursor: pointer" (click)="modalDeleteAccount.close('clearCart')">ANNULER</span>
      </div>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>
<ng-template #ConfirmationModalComponent let-ConfirmationModalComponent>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="ConfirmationModalComponent.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModal">Un code de confirmation a été envoyé</p>
      <div class="form-group w-100">
        <label for="code">Entrez le code de confirmation</label>
        <input [(ngModel)]="code" type="text" class="form-control" id="code" />
      </div>
      <div class="d-flex justify-content-between w-100">
        <button
          class="btn btn-success mt-3 mb-3 saveAdresse"
          (click)="confirmPhoneNumber(phoneNumber, code); ConfirmationModalComponent.close('confirm')"
        >
          CONFIRMER
        </button>
      </div>
      <div class="d-flex align-items-center down-input">
        <span style="cursor: pointer" (click)="ConfirmationModalComponent.dismiss('cancel')">ANNULER</span>
      </div>
    </div>
  </div>
</ng-template>
