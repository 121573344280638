import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import SharedModule from '../shared/shared.module';
import { AccountService } from '../core/auth/account.service';
import { Account } from '../core/auth/account.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from './service/home-service.service';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../cart/cart.service';
@Component({
  standalone: true,
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [CommonModule, SharedModule, RouterModule, GoogleMapsModule, NgxSkeletonLoaderModule, InfiniteScrollModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export default class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mapComponent', { static: false }) mapComponent!: GoogleMap;
  account: Account | null = null;
  numbers: any[] = Array.from({ length: 9 }, () => 0); // Initialize array with 6 zeros
  profileImageUrl: string | ArrayBuffer | null = null;
  openedProduct: any;
  openedCommand: any;
  activeButton: string = 'left';
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalProduct') modalProduct: any;
  @ViewChild('modalDetailsPharmacy') modalDetailsPharmacy: any;
  @ViewChild('MapModalComponent') MapModalComponent: any;
  @ViewChild('modalOrdonnance') modalOrdonnance: any;
  @ViewChild('modalAvis') modalAvis: any;
  @ViewChild('modalgeoAdresse') modalgeoAdresse: any;
  @ViewChild('modalgeoTow') modalgeoTow: any;
  @ViewChild('modalMaps') modalMaps: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild('mapElement', { static: true }) mapElement!: ElementRef;

  step1 = 'initial';
  step2 = '';
  step3 = '';
  commandDetails = false;
  value: number = 1;
  private readonly destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  isExpanded = false;
  likeImagePath: string = '../../content/images/Icon feather-heart (1).svg';
  pharmacy: any[] = [];
  currentPage: number = 0;
  itemsPerPage = 9;
  totalPages: number = 1;
  isLoading: boolean = false;
  toggleLoading = () => (this.isLoading = !this.isLoading);
  lengthLike: number = 0;
  likeCounter: number = 0;
  contentLoaded: boolean = false;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  addresses: any[] = [];
  public place!: google.maps.places.PlaceResult;
  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 46.603354, lng: 1.888334 };
  zoom: number = 13;
  latitude: number = 0;
  longitude: number = 0;
  isByLat!: boolean;
  // zoom: number = 15;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 46.603354, lng: 1.888334 },
    zoom: this.zoom,
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  markerPosition!: google.maps.LatLngLiteral;
  showMap: boolean = false;

  adresseForm = new FormGroup({
    appartmentNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    streetNumber: new FormControl(''),
    streetName: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postalCode: new FormControl(''),
    country: new FormControl(''),
  });
  constructor(
    private accountService: AccountService,
    private router: Router,
    private modalService: NgbModal,
    private homeService: HomeServiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getAuthState();
    this.getSearchKey();
    this.loadAddresses();
  }

  mapMarker!: MapMarker;
  openInfoWindow(marker: MapMarker, pharma: any) {
    this.selectedPharmacy = pharma;
    this.infoWindow.open(marker);
  }
  addAdressToclient() {
    if (this.adresseForm.valid) {
      this.homeService.addAdresse(this.adresseForm.value).subscribe(
        data => {
          this.toastr.success('Adresse ajoutée avec succès');
          this.loadAddresses();
        },
        error => {
          this.toastr.error("Erreur lors de l'ajout de l'adresse");
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }

  loadAddresses() {
    this.homeService.getAdresse().subscribe(
      data => {
        this.addresses = data;
        this.handleAddresses();
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des adresses');
      },
    );
  }
  handleAddresses() {
    if (this.addresses && this.addresses.length > 0) {
      // Filtrer et trier les adresses par `lastUsedDate`
      this.recentAddress = this.addresses
        .filter((address: any) => address.lastUsedDate)
        .sort((a: any, b: any) => new Date(b.lastUsedDate).getTime() - new Date(a.lastUsedDate).getTime())[0];
      console.log('Dernière adresse utilisée', this.recentAddress);

      if (this.recentAddress) {
        this.searchPharmacyLatLng(this.recentAddress.latitude, this.recentAddress.longitude, this.currentPage);
        this.isByLat = true;
      } else {
        this.getPharmacies();
        this.isByLat = false;
      }
    } else {
      this.getPharmacies();
    }
  }
  selectedAddressId: number | null = null;
  cookiesCart!: any[];
  total: number = 0;

  NavigateToPharmacy(id: number, pharmacyName: any) {
    this.router.navigate(['/pharmacie-category', { pharmacieId: id, T: 'P' }]);
    this.setPharmacyName(pharmacyName);
    this.setPharmacyId(id);
  }
  openModal(modalRef: any) {
    this.modalService.open(modalRef, { size: 'l', centered: true });
  }
  setPharmacyName(commercialName: any) {
    this.homeService.setCommercialName(commercialName);
  }
  setPharmacyId(pharmacyId: any) {
    this.homeService.setPharmacyId(pharmacyId);
  }

  // getSearchKey() {
  //   this.route.queryParams.subscribe(params => {
  //     const searchkeys = params['searchkeys'] || '';
  //     if (searchkeys) {
  //       this.searchPharmacies(searchkeys);
  //     }
  //   });
  // }
  // searchPharmacies(searchkeys: string): void {
  //   this.homeService.searchPharmacies(searchkeys).subscribe((data: any) => {
  //     this.pharmacy = data;
  //   });
  // }
  // onScroll = () => {
  //   console.log('Checking scroll action, currentPage:', this.currentPage);
  //   if (this.isByLat) {
  //     this.searchPharmacyLatLng(this.recentAddress.latitude, this.recentAddress.longitude, this.currentPage);
  //   } else {
  //     this.getPharmacies();
  //   }
  // };
  isSearchActive = false;

  getSearchKey() {
    this.route.queryParams.subscribe(params => {
      const searchkeys = params['searchkeys'] || '';
      if (searchkeys) {
        this.searchPharmacies(searchkeys);
      }
    });
  }

  searchPharmacies(searchkeys: string): void {
    this.homeService.searchPharmacies(searchkeys).subscribe({
      next: (data: any) => {
        this.pharmacy = data;
        this.isSearchActive = true;
      },
      error: (err: any) => {
        console.error('Erreur lors de la recherche:', err);
        this.isSearchActive = false;
      },
    });
  }
  onScroll = () => {
    if (this.isSearchActive) {
      console.log('Scroll désactivé car une recherche est en cours.');
      return;
    }

    console.log('Défilement autorisé, currentPage:', this.currentPage);

    if (this.isByLat) {
      this.searchPharmacyLatLng(this.recentAddress.latitude, this.recentAddress.longitude, this.currentPage);
    } else {
      this.getPharmacies();
    }
  };

  options: any = {
    icon: {
      url: '../../content/images/PG_Pin.svg',
      scaledSize: new google.maps.Size(50, 50),
    },
  };

  getPharmacies(): void {
    this.toggleLoading();
    console.log('Loading pharmacies, currentPage:', this.currentPage);
    this.homeService.getPharmacy(this.currentPage, 9).subscribe(
      data => {
        this.processPharmacies(data);
      },
      error => {
        this.toggleLoading();
        console.error('Error fetching pharmacies:', error);
      },
    );
  }
  searchPharmacyLatLng(lat: any, lng: any, page: any): void {
    this.homeService.searchPharmaciesWithLatLang(lat, lng, page).subscribe((data: any) => {
      this.processPharmacies(data);
    });
  }

  processPharmacies(data: any): void {
    this.pharmacy = [...this.pharmacy, ...data];
    this.currentPage++;
    if (this.pharmacy.length > 0) {
      const latitudes = this.pharmacy.map(ph => ph.latitude);
      const longitudes = this.pharmacy.map(ph => ph.longitude);
      const averageLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
      const averageLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
      this.center = { lat: averageLat, lng: averageLng };
    }
    this.toggleLoading();
  }

  appendData = () => {
    if (!this.isByLat) {
      this.toggleLoading();
      this.homeService.getPharmacy(this.currentPage, this.itemsPerPage).subscribe({
        next: data => {
          if (Array.isArray(data)) {
            data.forEach((element: any) => {
              if (typeof element === 'object' && element !== null) {
                this.pharmacy = [...this.pharmacy, element];
              } else {
                console.error('Invalid element:', element);
              }
            });
          } else {
            console.error('Data is not an array:', data);
          }
        },
        error: err => console.log(err),
        complete: () => this.toggleLoading(),
      });
    }
  };

  id: any;
  recentAddress: any;
  getAuthState() {
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      if (
        (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
        this.account?.authorities.includes('ROLE_PHARMACIST')
      ) {
      }
    });
  }
  toggleLike(pharma: any, index: number) {
    if (pharma.liked) {
      this.unlikePharmacy(pharma.id, index);
    } else {
      this.likePharmacy(pharma.id, index);
    }
  }
  likePharmacy(pharmacy_id: any, index: number) {
    let data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy_id,
    };
    this.homeService.likePharmacy(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.pharmacy[index].liked = true;
        this.lengthLike++;
        this.updateLikeCounter();
      },
      error => {
        console.error('Error liking pharmacy:', error);
      },
    );
  }
  unlikePharmacy(pharmacy_id: any, index: number) {
    let data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy_id,
    };
    this.homeService.unlikePharmacy(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.pharmacy[index].liked = false;
        this.lengthLike--;
        this.updateLikeCounter();
      },
      error => {
        console.error('Error unliking pharmacy:', error);
      },
    );
  }
  updateLikeCounter() {
    this.likeCounter = this.pharmacy.filter(pharma => pharma.liked).length;
    this.homeService.updateLikeCounter(this.likeCounter);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngAfterViewInit() {
    if (this.account && this.account.authorities.includes('ROLE_USER') && !this.addresses) {
      this.modalService.open(this.modalgeo, { size: 'l', centered: true });
      // this.modalService.open(this.modalAvis, { size: 'm', centered: true });
    }
  }
  selectedPharmacy: any;
  onMarkerClick(pharmacy: any): void {
    this.selectedPharmacy = pharmacy;
    console.log(pharmacy, 'pharmacy');
    this.modalService.open(this.modalDetailsPharmacy, { size: 'm', centered: true });
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  openMapInHome() {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
    this.markerPosition = { lat: 46.553354, lng: 1.925334 };
  }
  newCenterLat!: number;
  newCenterLng!: number;
  logCenter() {
    this.showSearchButton = true;
    console.log(this.mapComponent?.getCenter());
    const newCenter: any = this.mapComponent?.getCenter();
    console.log(newCenter.lat(), newCenter.lng(), 'newCenter.lat,newCenter.lng');
    this.newCenterLat = newCenter.lat();
    this.newCenterLng = newCenter.lng();
  }
  searchInCurrentArea() {
    this.searchPharmacyLatLng(this.newCenterLat, this.newCenterLng, this.currentPage);
  }

  showSearchButton: boolean = false;
  updateMapLocation(lat: number, lng: number) {
    if (this.map) {
      this.map.setCenter({ lat, lng });
      this.markerPosition = { lat, lng };
    }
  }

  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  activateListItem(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;

    const allLiElements = document.querySelectorAll('li.nav-item.dropdown.pointer');
    allLiElements.forEach(li => {
      if (li !== target) {
        li.classList.remove('active');
      }
    });
    // Get the ID of the target element
    const id = target.id;
    if (!target.classList.contains('active')) {
      target.classList.add('active');
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  activateButton(button: string) {
    this.activeButton = button;
    if (button === 'right') {
      this.showMap = true;
    } else {
      this.showMap = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  increment() {
    this.value++;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  confirmOrder() {
    // Implement order confirmation logic
    alert('Order confirmed!');
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const fileName = inputElement.files[0].name;
      const spanElement = inputElement.nextElementSibling as HTMLElement;

      if (spanElement) {
        spanElement.textContent = fileName;
      }
    }
  }
  onImgSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        // Explicitly check that the result is not undefined
        if (e.target && e.target.result) {
          this.profileImageUrl = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    }
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

  onGermanAddressMapped($event: any) {}

  public selectedPlace!: google.maps.places.PlaceResult;

  onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.selectedPlace = place;

    console.log(this.selectedPlace.formatted_address);
    console.log(this.selectedPlace, 'okk', place.address_components);
    if (this.account && this.account.applicationUser) {
      const applicationUser = {
        ...this.account.applicationUser,
        deliveryAddress: this.selectedPlace.formatted_address,
      };
    } else {
      console.error('Application user is not defined.');
    }

    if (place.address_components) {
      this.ngZone.run(() => {
        const components = place.address_components as google.maps.GeocoderAddressComponent[]; // Cast to ensure the type
        console.log(components, 'okokdoqsd');
        this.adresseForm.setValue({
          appartmentNumber: this.adresseForm.get('appartmentNumber')?.value || '',
          name: this.adresseForm.get('name')?.value || '',
          streetNumber: components[5].long_name,
          streetName: components[0].long_name,
          city: components[1].long_name,
          state: components[3].long_name,
          postalCode: components[5].long_name,
          country: components[4].long_name,
        });
      });
    } else {
      console.error('Address components are not available.');
    }
  }

  onSaveAddress() {
    if (this.selectedPlace) {
      this.searchPharmacies(this.selectedPlace.formatted_address || '');
    } else {
      console.error('Selected place is not defined.');
    }
  }
  useCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.updateMapLocation(this.latitude, this.longitude);
        this.searchPharmacyLatLng(this.latitude, this.longitude, this.currentPage);
      });
    } else {
      alert('Géolocalisation non supportée par votre navigateur');
    }
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  openMapModal() {
    this.modalService.open(this.MapModalComponent, { size: 'm', centered: true });
  }
}
