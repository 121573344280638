<div class="d-flex">
  <span style="margin-top: 1rem">
    <a (click)="NavigateToPharmacy()">{{ pharmacyName }}</a> / <a (click)="goBack()">{{ catName }}</a> / {{ subCatName }}</span
  >
</div>
<div class="row mt-4 mb-4">
  <div class="col-md-12 col-lg-5">
    <h2 class="lead" style="color: #0078c6" (click)="openModalProductExist(modalProductExist)">{{ subCatName }}</h2>
  </div>

  <div class="col-md-12 col-lg-7 d-sm-flex justify-content-lg-end flex-column flex-sm-row">
    <div class="product-search m-2">
      <input
        type="text"
        class="form-control category-input"
        id="text"
        name="search"
        placeholder="Produit ..."
        (keyup)="applyFilter($event)"
        data-cy="text"
      />
      <span class="search-icon"></span>
    </div>

    <button class="btn btn-primary btn-bill m-2 mr-0" (click)="openCommandModal(modalOrdonnance)">Déposer une ordonnance</button>
  </div>
</div>
<section infiniteScroll class="search-results" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
  <div class="container">
    <div class="row">
      <div class="pharmacies-list col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-4 mb-3" *ngFor="let product of products; let i = index">
        <div>
          <!--<jhi-product-card [product]="product" (click)="openproductModal(modalProduct, product)"></jhi-product-card>-->

          <div *ngIf="!contentLoaded" class="card product-card" style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer">
            <ngx-skeleton-loader
              count="1"
              appearance="circle"
              class="prod-img-top"
              [theme]="{ width: '80px', height: '80px', 'border-radius': '10px' }"
            ></ngx-skeleton-loader>
            <div class="card-body" style="display: flex; flex-direction: column">
              <ngx-skeleton-loader count="1" appearance="line" style="width: 60%; height: 20px"></ngx-skeleton-loader>
              <div class="d-flex flex-row mb-3"></div>
              <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
              <div class="d-flex flex-row align-items-center justify-content-between" style="margin-top: 10px">
                <div class="col-5 d-flex align-items-center flex-column"></div>
              </div>
            </div>
          </div>
          <div *ngIf="contentLoaded" class="card product-card" style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer">
            <img [src]="imageUrl + product?.product?.productImage + '/large'" class="prod-img-top" alt="Image" />
            <div class="card-body" style="display: flex; flex-direction: column">
              <h5 class="card-title" (click)="openproductModal(modalProduct, product)">{{ product?.product?.title }}</h5>
              <div class="d-flex flex-row mb-3">
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
              </div>
              <a *ngIf="product.discount != '0'" class="btn btn-light d-flex delivery">promo </a>
              <a class="btn btn-light d-flex likeBtn" (click)="toggleLike(product, i)">
                <img
                  [src]="
                    product.liked
                      ? '../../content/images/icons/Icon feather-heart (3).svg'
                      : '../../content/images/icons/Icon feather-heart (4).svg'
                  "
                  alt="Like"
                />
              </a>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div class="col-5 d-flex align-items-center flex-column">
                  <h3
                    *ngIf="product.discount != '0'"
                    class="price"
                    style="font-size: 15px; text-decoration: line-through; font-family: 'ClashDisplay-Regular'"
                  >
                    {{ product.price.toFixed(2) }}
                  </h3>
                  <h1 class="price m-auto">{{ (product.price * (1 - product.discount / 100)).toFixed(2) }}€</h1>
                </div>
                <div class="col-7 d-flex justify-content-end">
                  <a class="btn order" (click)="addToCart(product, modalProductExist)">Ajouter au panier</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div *ngIf="contentLoaded && products && products.length === 0">
  <lottie-player
    src="../../content/images/noData.json"
    background="transparent"
    speed="1"
    style="width: 300px; height: 250px; margin: auto"
    loop
    autoplay
  ></lottie-player>
</div>

<ng-template #modalOrdonnance let-modalOrdonnance>
  <div class="modal-command">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <span class="title">
          Nouvelle Commande chez Pharmacie du bien être
          <span class="command-id">ID: 0714528/24 </span>
        </span>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="PurchasesForm">
        <div class="row">
          <div class="col-sm-6">
            <label for="firstName">Prénom</label>
            <input type="text" name="firstName" id="firstName" />
          </div>
          <div class="col-sm-6">
            <label for="lastName">Nom</label>
            <input type="text" name="lastName" id="lastName" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4">
            <label for="ordonnance">Ordonnance</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event)" id="ordonnance" />
              <span class="file-placeholder"></span>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4">
            <label for="carte-vitale">Carte Vitale</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event)" id="carte-vitale" />
              <span class="file-placeholder"></span>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4">
            <label for="mutuelle">Carte mutuelle</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event)" id="mutuelle" />
              <span class="file-placeholder"></span>
            </div>
          </div>
        </div>
        <div class="modal-bottom">
          <label for="consentement" class="consentement">
            <input id="consentement" type="checkbox" name="consentement" class="form-check-input" /> Je valide mon consentement pour le
            sauvegarde de mes données pour 90 jours</label
          >
        </div>
        <button class="submit" type="button" (click)="addPurchases()">Confirmer</button>
        <button type="button" class="btn btn-annuler" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">Annuler</button>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #modalProduct let-modalProduct>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <span class="title">name/{{ openedProduct?.product?.title }}</span>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalProduct.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex row align-items-center">
        <div class="col-12 col-lg-5">
          <div class="prod-img">
            <img [src]="imageUrl + openedProduct?.product?.productImage + '/large'" class="product-card prod-img-top" alt="Image" />
          </div>
        </div>
        <div class="col-12 col-lg-7 ml-4">
          <h3 class="name">{{ openedProduct?.product?.title }}</h3>
          <div class="product-card card-body" style="display: flex; flex-direction: column">
            <div class="d-flex flex-row align-items-center product-rating">
              <div class="d-flex flex-row">
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
              </div>
              <span class="available ml-3">En stock</span>
            </div>
            <div class="producer">
              <img src="../../content/images/hyfaccc-removebg-preview.png" alt="" style="width: 100%" />
            </div>
            <p class="mb-3 descriptionDetail" [ngClass]="{ expanded: isExpandedDescription }" (click)="toggleExpandDescription()">
              {{ openedProduct?.product?.description }}
            </p>
            <h3 class="price">{{ (openedProduct?.price * (1 - openedProduct?.discount / 100)).toFixed(2) }}€</h3>
            <div class="d-flex flex-row align-items-center">
              <div class="col-2 d-flex align-items-center" style="margin-right: 1rem">
                <div class="number d-flex">
                  <span (click)="decrement()">-</span>
                  <span>{{ value }}</span>
                  <span (click)="increment()">+</span>
                </div>
              </div>
              <div class="col d-flex ml-3">
                <a class="btn order" (click)="addToCartWithASpecificNumber(); modalProduct.dismiss('Cross click')">Ajouter au panier</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-column description">
        <div class="tab-container">
          <ul class="tabs">
            <li [ngClass]="{ active: activeTab === 'composition' }" (click)="setActiveTab('composition')">Composition</li>
            <li [ngClass]="{ active: activeTab === 'posologie' }" (click)="setActiveTab('posologie')">Posologie</li>
          </ul>
          <div class="tab-content">
            <div *ngIf="activeTab === 'composition'">
              <p class="detail" [ngClass]="{ expanded: isExpanded }" (click)="toggleExpand()">
                Composition ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
              </p>
            </div>
            <div *ngIf="activeTab === 'posologie'">
              <p class="detail" [ngClass]="{ expanded: isExpanded }" (click)="toggleExpand()">
                Posologie ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalProductExist let-modalProductExist>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalProductExist.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />

      <p class="swalModal">Vous avez ajouté un produit qui existe dans une autre pharmacie</p>
      <span class="mb-4 productExist">
        Vous devez choisir entre payer le panier de la pharmacie <span style="color: #0078c6">{{ pharmacyCartItemsName }}</span
        >, ou vider votre panier et démarrer un nouveau panier avec la pharmacie <span style="color: #0078c6">{{ pharmacyName }}</span
        >.
      </span>

      <div class="d-flex justify-content-between w-100"></div>
      <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalProductExist.dismiss('cancel')">PROCÉDER AU PAIEMENT</button>
      <div class="d-flex align-items-center down-input">
        <span style="cursor: pointer" (click)="modalProductExist.close('clearCart')">VIDER LA PANIER</span>
      </div>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>
