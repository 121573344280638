import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideClientHydration, Title } from '@angular/platform-browser';
import { RouterFeatures, TitleStrategy, provideRouter, withComponentInputBinding, withDebugTracing } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DEBUG_INFO_ENABLED } from './app.constants';
import './config/dayjs';
import { TranslationModule } from './shared/language/translation.module';
import { httpInterceptorProviders } from './core/interceptor/index';
import routes from './app.routes';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { NgbDateDayjsAdapter } from './config/datepicker-adapter';
import { AppPageTitleStrategy } from './app-page-title-strategy';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FirebaseConfigModule } from './firebase-config.module';
import { CommonModule } from '@angular/common';

const routerFeatures: Array<RouterFeatures> = [withComponentInputBinding()];
if (DEBUG_INFO_ENABLED) {
  routerFeatures.push(withDebugTracing());
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, ...routerFeatures),
    importProvidersFrom(CommonModule),
    provideRouter(routes),
    provideClientHydration(),
    importProvidersFrom([BrowserAnimationsModule]),
    // Set this to true to enable service worker (PWA)
    // importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })),
    importProvidersFrom(TranslationModule),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(FirebaseConfigModule),
    importProvidersFrom(
      ToastrModule.forRoot({
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
        enableHtml: true,
        timeOut: 7000,
      }),
    ),
    Title,
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: NgbDateAdapter, useClass: NgbDateDayjsAdapter },
    httpInterceptorProviders,
    { provide: TitleStrategy, useClass: AppPageTitleStrategy },
    // jhipster-needle-angular-add-module JHipster will add new module here
  ],
};
