import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  addSchedules(headers: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/schedules`, headers);
  }
  putSchedules(id_schedule: number, headers: any): Observable<any> {
    return this.http.put(`${environment.baseUrl}api/schedules/${id_schedule}`, {}, { headers });
  }
  getSchedules(id_schedule: any) {
    return this.http.get<any>(`${environment.baseUrl}api/schedules`);
  }
  getSchedulesPeriod(pharmacy_id: any, fromdate: any, todate: any) {
    return this.http.get<any>(`${environment.baseUrl}api/schedules?pharmacyId=${pharmacy_id}&fromDate=${fromdate}&toDate=${todate}`);
  }
  deleteSchedules(id_schedule: any): Observable<HttpResponse<{}>> {
    return this.http.delete(`${environment.baseUrl}api/schedules/${id_schedule}`, { observe: 'response' });
  }
}
