<div class="conditions-utilisation">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="conditionsGenerales.title"></h1>
  </div>
  <div class="content">
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section3.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section3.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section4.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section4.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section4.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section4.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section4.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section5.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section5.text"></p>
      <ul>
        <li jhiTranslate="conditionsGenerales.section5.list.item1"></li>
        <li jhiTranslate="conditionsGenerales.section5.list.item2"></li>
        <li jhiTranslate="conditionsGenerales.section5.list.item3"></li>
      </ul>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section6.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section6.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section6.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section6.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section6.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section7.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section7.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section8.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section8.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section9.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section9.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section9.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGenerales.section9.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section9.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section10.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section10.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section11.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section11.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGenerales.section12.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGenerales.section12.text"></p>
    </div>
  </div>
</div>
<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span
        >© 2024 <span class="pharma">PharmaGo</span> .
        <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
