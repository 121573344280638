import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Account } from '../../core/auth/account.model';
import { AccountService } from '../../core/auth/account.service';
import { LoginService } from '../../login/login.service';
import SharedModule from '../../shared/shared.module';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'jhi-home-admin',
  standalone: true,
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss'],
  imports: [SharedModule, RouterModule],
})
export class HomeAdminComponent implements OnInit {
  account: Account | null = null;
  numbers: any[] = Array.from({ length: 9 }, () => 0); // Initialize array with 6 zeros
  activeButton: string = 'left';
  private readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private router: Router,
    private loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.accountService
      .getAuthenticationState()
      .pipe(takeUntil(this.destroy$))
      .subscribe(account => (this.account = account));
    console.log(this.account);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @angular-eslint/use-lifecycle-interface, @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {}
  login(): void {
    this.router.navigate(['/login']);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
