import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
// import {
//   ChartComponent,
//   ApexAxisChartSeries,
//   ApexChart,
//   ApexXAxis,
//   ApexDataLabels,
//   ApexTooltip,
//   ApexStroke,
//   NgApexchartsModule,
//   ApexPlotOptions,
//   ApexYAxis,
//   ApexFill,
//   ApexLegend,
// } from 'ng-apexcharts';

// export type ChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   yaxis: ApexYAxis;
//   xaxis: ApexXAxis;
//   fill: ApexFill;
//   tooltip: ApexTooltip;
//   stroke: ApexStroke;
//   legend: ApexLegend;
// };

@Component({
  selector: 'jhi-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule],
  // NgApexchartsModule
})
export class DashboardComponent implements OnInit {
  // @ViewChild('chart1') chart1: ChartComponent;
  // @ViewChild('chart2') chart2: ChartComponent;
  // public chartOptions1: ChartOptions;
  // public chartOptions2: ChartOptions;

  constructor() {
    // this.chartOptions1 = {
    //   series: [
    //     {
    //       name: 'series1',
    //       data: [31, 40, 28, 51, 42, 109, 100],
    //     },
    //     {
    //       name: 'series2',
    //       data: [11, 32, 45, 32, 34, 52, 41],
    //     },
    //   ],
    //   chart: {
    //     height: 350,
    //     type: 'area',
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     curve: 'smooth',
    //   },
    //   xaxis: {
    //     type: 'datetime',
    //     categories: [
    //       '2018-09-19T00:00:00.000Z',
    //       '2018-09-19T01:30:00.000Z',
    //       '2018-09-19T02:30:00.000Z',
    //       '2018-09-19T03:30:00.000Z',
    //       '2018-09-19T04:30:00.000Z',
    //       '2018-09-19T05:30:00.000Z',
    //       '2018-09-19T06:30:00.000Z',
    //     ],
    //   },
    //   tooltip: {
    //     x: {
    //       format: 'dd/MM/yy HH:mm',
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: '55%',
    //     },
    //   },
    //   yaxis: {
    //     title: {
    //       text: 'Value',
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //   },
    //   legend: {
    //     show: true,
    //   },
    // };
    // this.chartOptions2 = {
    //   series: [
    //     {
    //       name: 'Net Profit',
    //       data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    //     },
    //     {
    //       name: 'Revenue',
    //       data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    //     },
    //     {
    //       name: 'Free Cash Flow',
    //       data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    //     },
    //   ],
    //   chart: {
    //     type: 'bar',
    //     height: 350,
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: '55%',
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     show: true,
    //     width: 2,
    //     colors: ['transparent'],
    //   },
    //   xaxis: {
    //     categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    //   },
    //   yaxis: {
    //     title: {
    //       text: '$ (thousands)',
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //   },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {
    //         return '$ ' + val + ' thousands';
    //       },
    //     },
    //   },
    //   legend: {
    //     show: true,
    //   },
    // };
  }

  ngOnInit(): void {}
}
