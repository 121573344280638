import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private s3: AWS.S3;

  constructor(private http: HttpClient) {
    AWS.config.update({
      accessKeyId: '4490a585d93345348725fc091e1bdcd2',
      secretAccessKey: 'd094bb9ad86c412dac93ce699e7807aa',
      region: 'sbg',
    });

    this.s3 = new AWS.S3({
      endpoint: 'https://s3.sbg.io.cloud.ovh.net/',
      s3ForcePathStyle: true, // Nécessaire pour utiliser l'API S3 d'OVH
    });
  }

  getPurchases(first: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/purchases/owner/ROLE_USER?page=${first}&size=50`);
  }
  getPurchasesPharmacist(page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/purchases/owner/ROLE_PHARMACIST?page=${page}&size=50`);
  }
  getPurchaseById(purchase_id: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/purchases/${purchase_id}`);
  }
  deletePurchases(purchase_id: any): Observable<any> {
    return this.http.delete(`${environment.baseUrl}api/purchases/${purchase_id}`);
  }
  acceptPurchases(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/purchases/event`, data);
  }
  ConfirmedPurchases(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/purchases/event`, data);
  }
  getFileUrl(purchaseId: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/files/${purchaseId}/documents/all`);
  }
  getImage(bucketName: any, key: any): Promise<any> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3.getObject(params).promise();
  }
  addExtraAmountPurchase(data: any, purchaseId: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/purchases/extra_amount/${purchaseId}`, data);
  }
  addReports(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/reports`, data);
  }
  getReports(idClient: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/reports/client/${idClient}`);
  }
}
