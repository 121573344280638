@if (!params) {
  <section class="section-home">
    <div class="container" style="margin-top: 50px">
      <div class="row">
        <div *ngIf="screenWidth > 768; px" class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 class="title">PharmaGo</h1>
          <p style="white-space: break-spaces" jhiTranslate="global.menu.presentation"></p>
          <div class="col-md-6 d-flex row-direction">
            <div class="col-md-6" style="margin-right: 1rem">
              <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/7d19ea52-f892-4e81-a871-545bcbd26100/Cpub" alt="" />
            </div>
            <div class="col-md-6">
              <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/a0f41060-2220-409d-246f-2a9e25776d00/Cpub" alt="" />
            </div>
          </div>
          <div class="col-md-4">
            <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/29366035-9d2e-4bb0-e859-66b179d97400/Cpub" alt="" />
          </div>
        </div>
        <div class="col-lg-6" [ngStyle]="{ 'padding-left': screenWidth > 1500 ? '6rem' : '15px' }">
          <div class="card login-card">
            <h1 jhiTranslate="login.title2" data-cy="loginTitle" class="loginTitle">Sign in</h1>
            <!-- @if (authenticationError) {
              <div class="alert alert-danger" jhiTranslate="login.messages.error.authentication" data-cy="loginError">
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
              </div>
            } -->
            <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
              <div class="">
                <label class="username-label" for="username" jhiTranslate="global.form.username.label">Username</label>
                <input
                  type="text"
                  class="form-control"
                  name="username"
                  id="username"
                  placeholder="{{ 'global.form.username.placeholder' | translate }}"
                  formControlName="username"
                  #username
                  data-cy="username"
                />
                @if (loginForm.get('username')!.invalid && (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)) {
                  <div>
                    @if (loginForm.get('username')?.errors?.required) {
                      <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.required"
                        >Your username is required.</small
                      >
                    }
                    @if (loginForm.get('username')?.errors?.invalid) {
                      <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.invalid"
                        >Your username is invalid.</small
                      >
                    }
                  </div>
                }
              </div>
              <div class="">
                <label for="password" jhiTranslate="login.form.password">Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  id="password"
                  placeholder="{{ 'login.form.password.placeholder' | translate }}"
                  formControlName="password"
                  data-cy="password"
                />
                <div *ngIf="loginForm.get('password')!.invalid && (loginForm.get('password')!.dirty || loginForm.get('password')!.touched)">
                  <div *ngIf="loginForm.get('password')?.errors?.required">
                    <small class="form-text text-danger" jhiTranslate="global.messages.validate.newpassword.required"
                      >Your password is required.</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-group d-flex justify-content-between mb-3 flex-wrap">
                <div class="form-check">
                  <label class="form-check-label" for="rememberMe">
                    <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe" />
                    <span jhiTranslate="login.form.rememberme">Remember me</span>
                  </label>
                </div>
                <div>
                  <a
                    class="alert-link"
                    routerLink="/account/reset/request"
                    jhiTranslate="login.password.forgot"
                    data-cy="forgetYourPasswordSelector"
                    >Did you forget your password?</a
                  >
                </div>
              </div>
              <button type="submit" class="col-12 btn btn-primary success-btn" jhiTranslate="login.form.button" data-cy="submit">
                Sign in
              </button>
            </form>
            <div class="d-flex justify-content-center m-3 flex-wrap">
              <label jhiTranslate="global.messages.info.register.noaccount">Vous n'avez pas encore de compte ?</label>&nbsp;<a
                class="alert-link"
                routerLink="/account/register"
                [queryParams]="{ user: 'client' }"
                jhiTranslate="global.messages.info.register.link"
                >sign in</a
              >.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-works">
    <div class="container">
      <h1 jhiTranslate="global.menu.howworks" class="col-md-3">Comment ça fonctionne ?</h1>
      @if (screenWidth > 1024) {
        <div class="d-flex cards">
          <div class="d-flex flex-column align-items-center item">
            <div class="card1"><img src="../../content/images/icons/map.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.addressenter">Saisir votre adresse</h3>
          </div>
          <div class="line"></div>
          <div class="d-flex flex-column align-items-center item">
            <div class="card2"><img src="../../content/images/icons/chariot.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.ordertake">Passer votre commande</h3>
          </div>
          <div class="line"></div>
          <div class="d-flex flex-column align-items-center item">
            <div class="card1"><img src="../../content/images/icons/card.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.paycharges">Payer les <br />frais</h3>
          </div>
          <div class="line"></div>
          <div class="d-flex flex-column align-items-center item">
            <div class="card2"><img src="../../content/images/icons/delivery.svg" alt="" /></div>
            <h3 style="max-width: 250px" jhiTranslate="global.menu.delevryondoor">Le livreur frappe à la porte</h3>
          </div>
        </div>
      } @else {
        <div class="d-flex cards">
          <div class="d-flex flex-column align-items-center item">
            <div class="card1"><img src="../../content/images/icons/map.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.addressenter">Saisir votre adresse</h3>
          </div>
          <div class="line"></div>
          <div class="d-flex flex-column align-items-center item">
            <div class="card2"><img src="../../content/images/icons/chariot.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.ordertake">Passer votre commande</h3>
          </div>
        </div>
        <div class="d-flex cards">
          <div class="d-flex flex-column align-items-center item">
            <div class="card1"><img src="../../content/images/icons/card.svg" alt="" /></div>
            <h3 style="max-width: 200px" jhiTranslate="global.menu.paycharges">Payer les frais</h3>
          </div>
          <div class="line"></div>
          <div class="d-flex flex-column align-items-center item">
            <div class="card2"><img src="../../content/images/icons/delivery.svg" alt="" /></div>
            <h3 style="max-width: 250px" jhiTranslate="global.menu.delevryondoor">Le livreur frappe à la porte</h3>
          </div>
        </div>
      }
    </div>
  </section>
  <section class="mobile-app">
    <div class="container">
      <div class="card">
        <div class="col-lg-6 d-flex flex-column justify-content-evenly blue-car" style="padding: 3.5rem 2rem">
          <h1 jhiTranslate="global.menu.mobileapp" class="title">L’application Mobile</h1>
          <p>
            <span class="pharm">PharmaGo </span
            ><span jhiTranslate="global.menu.apppresent1"
              >est à portée de main où que vous soyez. Avec l’Application mobile, votre pharmacie est dans votre poche.</span
            >
            <br /><br />
            <span jhiTranslate="global.menu.apppresent2">Commencez maintenant</span>, <span class="pharm">PharmaGo</span
            ><span jhiTranslate="global.menu.apppresent3">
              est disponible en application mobile sur Android et iOS sur les liens ci dessous.</span
            >
          </p>
          <div class="col-md-12 d-flex row-direction">
            <div class="row">
              <div class="col-12 col-sm-6" style="max-width: 220px">
                <img
                  class="img-class"
                  src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/7d19ea52-f892-4e81-a871-545bcbd26100/Cpub"
                  alt=""
                />
              </div>
              <div class="col-12 col-sm-6" style="max-width: 220px">
                <img
                  class="img-class"
                  src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/a0f41060-2220-409d-246f-2a9e25776d00/Cpub"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" style="text-align: center">
          <img
            *ngIf="screenWidth > 990; px"
            style="max-height: 100%; scale: 1.1"
            src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/4fe07156-0f87-4f81-bae2-2bfd10111300/public"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <section class="background"></section>
  <section class="services">
    <div class="container">
      <h3 jhiTranslate="global.menu.ourservice" class="">Notre service</h3>
      <div class="cards-services">
        <div class="card one mr-1">
          <p jhiTranslate="global.menu.service1">Ajustez l’heure et le lieu de livraison selon votre disponibilité</p>
        </div>
        <div class="card two">
          <p jhiTranslate="global.menu.service2">Faites vos achats et payez en toute sécurité, sur votre espace privée</p>
        </div>
        <div class="card three">
          <p jhiTranslate="global.menu.service3">On prends soin de notre planète, en réduisant notre impacte écologique</p>
        </div>
      </div>
    </div>
  </section>
  <section class="stats">
    <div class="container">
      <h3 jhiTranslate="global.menu.trust" class="">Ils nous font confiance</h3>
      <div class="stat-cards">
        <div class="row">
          <div class="col-sm-6 col-xs-12 col-md-6">
            <div class="card one">
              <h1>+200</h1>
              <h4 jhiTranslate="global.menu.stat.title1">Pharmacie</h4>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12 col-md-6">
            <div class="card two">
              <h1>+450</h1>
              <h4 jhiTranslate="global.menu.stat.title2">Marques de produits</h4>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12 col-md-6">
            <div class="card three">
              <h1>+150</h1>
              <h4 jhiTranslate="global.menu.stat.title3">Livreurs éco-responsables</h4>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12 col-md-6">
            <div class="card four">
              <h1>+2000</h1>
              <h4 jhiTranslate="global.menu.stat.title4">Clients par jour</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="logos">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h3 jhiTranslate="global.menu.certifications" class="section-title">La plateforme est agrée par</h3>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="logo-item">
            <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/d9914928-0bd1-4734-d909-ae76989e0a00/public" alt="" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="logo-item">
            <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/30a5e425-c5aa-44ae-beae-e8711fd85f00/public" alt="" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="logo-item">
            <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/17578d83-0520-4e21-604a-f89654102c00/public" alt="" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="logo-item">
            <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/1ea58d00-fcb7-47b2-116c-bd8bf0b7a600/public" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about-us">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h3 jhiTranslate="global.menu.Qui-sommes-nous-title" class="section-title">Qui sommes-nous ?</h3>
          <p jhiTranslate="global.menu.Qui-sommes-nous-parag1">
            Notre plateforme est spécialisée dans la vente et la livraison de produits parapharmaceutiques et de médicaments en ligne,
            offrant un accès rapide et sécurisé à une large gamme de produits de santé. Actifs en France, nous nous engageons à fournir des
            services fiables et de qualité, adaptés aux besoins de nos clients.
          </p>
          <p jhiTranslate="global.menu.Qui-sommes-nous-parag2">
            Nous collaborons avec des professionnels de santé et des pharmacies agréées pour garantir l'authenticité et la sécurité des
            produits proposés. Notre mission est de simplifier l'accès aux soins en ligne, tout en respectant les réglementations en
            vigueur, pour assurer le bien-être de nos utilisateurs.
          </p>
        </div>
        <div class="col-sm-12 col-md-6">
          <img
            src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/0396868f-4735-43cf-c916-59b83b2aa600/public"
            alt="Pharmacy Image"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
} @else {
  <section class="conditions">
    <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/d7cc152e-db61-4f50-3230-604fa9e01c00/public" alt="" />
    <div class="container1">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <h1 jhiTranslate="global.form.{{ params }}.text"></h1>
      </div>
    </div>
    <div class="container">
      <h3>1- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <h3>2- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <h3>1- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <h3>2- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <h3>1- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <h3>2- Titre lorem ipsum</h3>
      <p>
        lorem ipsum Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
    </div>
  </section>
}
