import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '../../environments/environment';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
  ) {}

  sendTokenToServer(token: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/push-notification/tokens`, { token }).pipe(
      tap(response => {
        console.log('Token successfully sent to the server:', response);
      }),
    );
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        console.log('Token reçu:', token);
        this.sendTokenToServer(token).subscribe(
          response => {
            console.log('Token envoyé au serveur avec succès:', response);
          },
          error => {
            console.error('Erreur lors de l’envoi du token au serveur:', error);
          },
        );
      },
      error => {
        console.error('Erreur lors de la demande de permission:', error);
      },
    );
  }
  receiveMessage(): Observable<any> {
    return new Observable(observer => {
      this.angularFireMessaging.messages.subscribe(
        message => {
          console.log('Message received:', message);
          observer.next(message);
        },
        error => {
          console.error('Message reception failed', error);
          observer.error(error);
        },
      );
    });
  }
  getNotifications(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/push-notification/notifications`);
  }
  readNotification(notificationId: any): Observable<any> {
    return this.http.patch<any>(`${environment.baseUrl}api/push-notification/read/${notificationId}`, {});
  }
  deleteNotification(notificationId: any) {
    return this.http.delete(`${environment.baseUrl}api/push-notification/${notificationId}`);
  }
}
