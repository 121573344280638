import { Component, HostListener, NgZone, OnInit, Renderer2, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { StateStorageService } from '../../core/auth/state-storage.service';
import SharedModule from '../../shared/shared.module';
import HasAnyAuthorityDirective from '../../shared/auth/has-any-authority.directive';
import { VERSION } from '../../app.constants';
import { LANGUAGES } from '../../config/language.constants';
import { Account } from '../../core/auth/account.model';
import { AccountService } from '../../core/auth/account.service';
import { EntityNavbarItems } from '../../entities/entity-navbar-items';
import ActiveMenuDirective from './active-menu.directive';
import NavbarItem from './navbar-item.model';
import { filter } from 'rxjs';
import { HomeServiceService } from '../../home/service/home-service.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { CartService } from '../../cart/cart.service';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { IonLabel } from '@ionic/angular';
import { NotificationService } from '../../notification/notification.service';
import { ProfileService } from '../profiles/profile.service';
import { LoginService } from '../../login/login.service';

@Component({
  standalone: true,
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule,
    GoogleMapsModule,
    HasAnyAuthorityDirective,
    ActiveMenuDirective,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export default class NavbarComponent implements OnInit {
  @ViewChild('modalgeoAdd') modalgeoAdd: any;
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalgeoTow') modalgeoTow: any;
  @ViewChild('modalMaps') modalMaps: any;
  @ViewChild('mapComponent', { static: false }) mapComponent!: GoogleMap;

  addresses: any[] = [];
  address: any;

  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 46.603354, lng: 1.888334 };
  zoom: number = 10;
  latitude: number = 0;
  longitude: number = 0;
  markerPosition!: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  openAPIEnabled?: boolean;
  version = '';
  account: Account | null = null;
  accountFromService: any;
  entitiesNavbarItems: NavbarItem[] = [];
  disable = false;
  delfaultLang!: any;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  currentUrl: any;
  likeCounter: number = 0;
  searchForm: FormGroup;
  cartItemsCount: number = 0;
  cartItemsCountPrescription: number = 0;

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  adresseForm = new FormGroup({
    id: new FormControl(''),
    appartmentNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    streetNumber: new FormControl(''),
    streetName: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postalCode: new FormControl(''),
    country: new FormControl(''),
  });
  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private stateStorageService: StateStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router,
    private pharmacyService: HomeServiceService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private cartService: CartService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.disable = event.url.includes('/account/register') || event.url.includes('/login') || event.url.includes('/account/activate');
    });
    if (VERSION) {
      this.version = VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`;
    }
    this.currentUrl = router.url;
    this.searchForm = this.fb.group({
      searchkeys: [''],
    });
    this.cartService.cartItems$.subscribe(cartItems => {
      this.cartItemsCount = 0;
      cartItems.forEach(item => {
        const quantity = parseInt(item.quantity);
        if (!isNaN(quantity)) {
          this.cartItemsCount += quantity;
        }
      });
    });
    // this.cartService.cartItemsCount$.subscribe(cartItemsCount => {
    //   this.cartItemsCount = cartItemsCount;
    // });
  }

  ngOnInit(): void {
    this.delfaultLang = this.stateStorageService.getLocale()
      ? this.stateStorageService.getLocale()
      : this.translateService.getDefaultLang();
    this.entitiesNavbarItems = EntityNavbarItems;
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      console.log(this.account);
    });
    this.getAccount();
    // this.pharmacyService.likeCounter$.subscribe(count => {
    //   this.likeCounter = count;
    // });
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }
    if (this.account) {
      this.loadAddresses();
    }
    if (this.account) {
      this.getNotifications();
    }
  }
  getAccount() {
    this.accountService.getAuthenticatedUser().subscribe(account => {
      this.accountFromService = account;
      this.accountService.setAccount(account);
    });
  }
  changeLanguage(languageKey: string): void {
    this.stateStorageService.storeLocale(languageKey);
    this.translateService.use(languageKey);
    this.delfaultLang = languageKey;
    localStorage.setItem('selectedLanguage', languageKey);
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.collapseNavbar();

    // eslint-disable-next-line curly
    if (this.account?.authorities.includes('ROLE_ADMIN')) {
      this.loginService.logout();

      this.router.navigate(['/admin/signin']);
    } else {
      this.loginService.logout();

      this.router.navigate(['/']);
    }
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  clickElementByClassName(className: string): void {
    const element = document.querySelector(`.${className}`);
    if (element) {
      (element as HTMLElement).click(); // Ensure the element is cast to HTMLElement
    } else {
      console.warn(`Element with class name ${className} not found.`);
    }
  }

  goToCart(): void {
    this.clickElementByClassName('panier');
  }

  goToFavorit(): void {
    this.clickElementByClassName('favoris');
  }

  goToProfile() {
    this.clickElementByClassName('profile');
  }
  onSearch(): void {
    const searchkeys = this.searchForm.get('searchkeys')?.value;
    this.router.navigate(['/home'], { queryParams: { searchkeys } });
  }
  openModal(modalRef: any) {
    this.adresseForm.reset();
    this.modalService.open(modalRef, { size: 'l', centered: true });
  }

  useCurrentLocation() {}
  openMapInHome() {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
    this.markerPosition = { lat: 46.553354, lng: 1.925334 };
  }
  public selectedPlace!: google.maps.places.PlaceResult;

  onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.selectedPlace = place;

    console.log(this.selectedPlace.formatted_address);
    console.log(this.selectedPlace, 'okk', place.address_components);
    if (this.account && this.account.applicationUser) {
      const applicationUser = {
        ...this.account.applicationUser,
        deliveryAddress: this.selectedPlace.formatted_address,
      };
    } else {
      console.error('Application user is not defined.');
    }

    if (place.address_components) {
      this.ngZone.run(() => {
        const components = place.address_components as google.maps.GeocoderAddressComponent[]; // Cast to ensure the type
        console.log(components, 'okokdoqsd');
        this.adresseForm.patchValue({
          appartmentNumber: this.adresseForm.get('appartmentNumber')?.value || '',
          name: this.adresseForm.get('name')?.value || '',
          streetNumber: components[5].long_name,
          streetName: components[0].long_name,
          city: components[1].long_name,
          state: components[3].long_name,
          postalCode: components[5].long_name,
          country: components[4].long_name,
        });
      });
    } else {
      console.error('Address components are not available.');
    }
  }
  recentAddress: any;
  loadAddresses() {
    this.pharmacyService.getAdresse().subscribe(
      data => {
        this.addresses = data;
        this.recentAddress = this.addresses
          .filter((address: any) => address.lastUsedDate)
          .sort((a: any, b: any) => new Date(b.lastUsedDate).getTime() - new Date(a.lastUsedDate).getTime())[0];
        console.log('Dernière adresse utilisée', this.recentAddress);

        if (this.selectedAddressId) {
          const selectedAddress = this.addresses.find(address => address.id === this.selectedAddressId);
          if (selectedAddress) {
            this.onAddressSelection(selectedAddress);
          } else {
            this.selectedAddressId = null;
          }
        }
        if (!this.selectedAddressId && this.recentAddress) {
          this.onAddressSelection(this.recentAddress);
        }
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des adresses');
      },
    );
  }
  notifications: any;
  getNotifications() {
    this.notificationService.getNotifications().subscribe(
      data => {
        this.notifications = data;
        this.updateLikeCounter();
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des notifications', error);
      },
    );
  }

  formatNotificationDate(date: string): string {
    const now = new Date();
    const notificationDate = new Date(date);

    if (now.toDateString() === notificationDate.toDateString()) {
      return this.datePipe.transform(notificationDate, 'HH:mm') || '';
    } else {
      return this.datePipe.transform(notificationDate, 'dd/MM HH:mm') || '';
    }
  }

  updateLikeCounter() {
    if (this.notifications?.content) {
      this.likeCounter = this.notifications.content.filter((notif: any) => !notif.read).length;
    }
  }
  addressId: any;
  onAddressChange(address: any) {
    this.adresseForm.patchValue({
      id: address.id,
      appartmentNumber: address.appartmentNumber,
      name: address.name,
      streetNumber: address.streetNumber,
      streetName: address.streetName,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country,
    });
    this.address = address.streetName;
    this.addressId = address.id;
    this.modalService.open(this.modalgeoTow, { size: 'l', centered: true });
  }
  addAdressToclient() {
    if (this.adresseForm.valid) {
      this.pharmacyService.addAdresse(this.adresseForm.value).subscribe(
        data => {
          this.toastr.success('Adresse ajoutée avec succès');
          this.loadAddresses();
        },
        error => {
          this.toastr.error('Veuillez saisir une adresse complète!');
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }
  readNotif: any;
  // readNotification(notificationId:any) {
  //     this.notificationService.readNotification(notificationId).subscribe(
  //       data => {
  //         this.readNotif=data.read
  //         this.getNotifications();
  //       },
  //       error => {
  //       },
  //     );
  // }
  readNotification(notificationId: any) {
    this.notificationService.readNotification(notificationId).subscribe(
      data => {
        const index = this.notifications?.content.findIndex((notif: any) => notif.id === notificationId);
        if (index !== -1) {
          this.notifications.content[index].read = true;
        }
        this.getNotifications();
        const notification = this.notifications?.content.find((notif: any) => notif.id === notificationId);
        if (notification?.entityType === 'Purchase') {
          this.router.navigate(['/order-details', { purchase_id: notification.entityId }]);
        }
      },
      error => {
        console.error('Erreur lors de la lecture de la notification:', error);
      },
    );
  }
  updateAdresse() {
    if (this.adresseForm.valid) {
      this.pharmacyService.updateAdresse(this.adresseForm.value, this.addressId).subscribe(
        data => {
          this.toastr.success('Adresse modifieé avec succès');
          this.loadAddresses();
        },
        error => {
          this.toastr.error("Erreur lors de la modification de l'adresse");
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }
  selectedAddressId: number | null = null;
  onAddressSelection(address: any) {
    this.selectedAddressId = address.id;
  }
  onSave() {
    if (!this.selectedAddressId) {
      this.toastr.error('Veuillez sélectionner une adresse.');
      return;
    }
    const selectedAddress = this.addresses.find(address => address.id === this.selectedAddressId);
    if (!selectedAddress) {
      this.toastr.error('Adresse sélectionnée introuvable.');
      return;
    }
    const body = {
      id: selectedAddress.id,
      streetNumber: selectedAddress.streetNumber,
      streetName: selectedAddress.streetName,
      city: selectedAddress.city,
      postalCode: selectedAddress.postalCode,
      country: selectedAddress.country,
    };
    this.pharmacyService.saveAdress(selectedAddress.id, body).subscribe(
      response => {
        this.toastr.success('Adresse mise à jour avec succès');
        this.loadAddresses();
        this.getAccount();
        this.pharmacyService.setAddressCoordinates(response.latitude, response.longitude);
        localStorage.setItem('selectedAddressId', selectedAddress.id);
        localStorage.setItem('selectedAddressCoords', JSON.stringify({ lat: response.latitude, lng: response.longitude }));
        localStorage.setItem(
          'selectedAddress',
          JSON.stringify({ city: selectedAddress.city, country: selectedAddress.country, street: selectedAddress.streetName }),
        );
      },
      error => {
        this.toastr.error('Veuillez saisir une adresse complète!');
        console.error('Error updating address:', error);
      },
    );
  }

  deleteAdress(addressId: any) {
    this.pharmacyService.deleteAdress(addressId).subscribe(
      data => {
        console.log(data);
        this.loadAddresses();
        this.toastr.success('Adresse supprimé avec succès');
      },
      error => {
        console.log(error);
      },
    );
  }
  logCenter(event: any) {
    console.log(event, 'eventevent');
    console.log('mapComponent', this.mapComponent?.getCenter());
    const newCenter: any = this.mapComponent?.getCenter();
    console.log(event.latLng.lat(), event.latLng.lng(), 'newCenter.lat,newCenter.lng');
    this.getAddress(event.latLng.lat(), event.latLng.lng());
  }

  getAddress(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results: any, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });
  }
  reOpenAddressModal() {
    this.modalService.open(this.modalgeoAdd, { size: 'l', centered: true });
  }
  OpenAddressModal() {
    this.adresseForm.reset();
    this.modalService.open(this.modalgeoAdd, { size: 'l', centered: true });
  }
}
