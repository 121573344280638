<div class="conditions-generales-de-vente">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="conditionsGeneralesDeVente.title"></h1>
  </div>
  <div class="content">
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.3.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.3.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.4.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.4.subsections.4.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.4.subsections.4.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.4.subsections.4.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.4.subsections.4.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.5.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.5.subsections.5.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.5.subsections.5.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.5.subsections.5.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.5.subsections.5.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.6.title"></h2>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.6.subsections.6.1.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.6.subsections.6.1.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title sousTitre" jhiTranslate="conditionsGeneralesDeVente.sections.6.subsections.6.2.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.6.subsections.6.2.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.7.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.7.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.8.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.8.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.9.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.9.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.10.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.10.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.11.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.11.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.12.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.12.text"></p>
    </div>
    <div class="section">
      <h2 class="section-title" jhiTranslate="conditionsGeneralesDeVente.sections.13.title"></h2>
      <p class="section-text" jhiTranslate="conditionsGeneralesDeVente.sections.13.text"></p>
    </div>
  </div>
</div>
<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span
        >© 2024 <span class="pharma">PharmaGo</span> .
        <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
