import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../cart/cart.service';
import { HomeServiceService } from '../../home/service/home-service.service';
import SharedModule from '../../shared/shared.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'jhi-favorites',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FavoritesComponent implements OnInit {
  @ViewChild('modalProductExist') modalProductExist: any;

  likeImagePath: string = '../../content/images/icons/Icon feather-heart (4).svg';
  likeImagePathPharmacie: string = '../../content/images/Icon feather-heart (1).svg';
  activeTab: string = 'composition';
  favorisPharmacy: any;
  favorisProduct: any;
  lengthLike!: number;
  likeCounter!: number;
  openedProduct: any;
  value: number = 1;
  isExpandedDescription = false;
  isExpanded = false;
  pharmacyCartItemsName: any;
  pharmacyName: any;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';

  constructor(
    private pharmacieService: HomeServiceService,
    private router: Router,
    private homeService: HomeServiceService,
    private modalService: NgbModal,
    private cartService: CartService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.pharmacyCartItemsName = this.cartService.getPharmacyName();

    window.scrollTo(0, 0);
    this.getFavorisPharmacy();
    this.getFavorisProduct();
  }

  getFavorisPharmacy(): void {
    this.pharmacieService.getFavorisPharmacy().subscribe({
      next: (res: any) => {
        this.favorisPharmacy = res;
        console.log('favorisPharmacy', this.favorisPharmacy);
      },
      error: (err: any) => {
        console.error('Error fetching favorisPharmacy:', err);
      },
    });
  }
  getFavorisProduct(): void {
    this.pharmacieService.getFavorisProduct().subscribe({
      next: (res: any) => {
        this.favorisProduct = res;
        console.log('favorisProduct', this.favorisProduct);
      },
      error: (err: any) => {
        console.error('Error fetching favorisproduct:', err);
      },
    });
  }
  increment() {
    this.value++;
  }
  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  NavigateToPharmacy(id: number, pharmacyName: any) {
    this.router.navigate(['/pharmacie-category', { pharmacieId: id, T: 'P' }]);
    this.setPharmacyName(pharmacyName);
    this.setPharmacyId(id);
  }
  setPharmacyName(commercialName: any) {
    this.homeService.setCommercialName(commercialName);
  }
  setPharmacyId(pharmacyId: any) {
    this.homeService.setPharmacyId(pharmacyId);
  }
  unlikePharmacy(pharmacy: any, index: number) {
    let data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy.id,
    };
    this.pharmacieService.unlikePharmacy(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.favorisPharmacy[index].liked = false;
        this.lengthLike = this.favorisPharmacy.length;
        this.lengthLike--;
        this.getFavorisPharmacy();
        this.updateLikeCounter();
      },
      error => {
        console.error('Error unliking pharmacy:', error);
      },
    );
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  unlikeProduct(product: any, index: number) {
    let data = {
      favorable_type: 'StockItem',
      favorable_id: product.id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.favorisProduct[index].liked = false;
        this.getFavorisProduct();
      },
      error => {
        console.error('Error unliking pharmacy:', error);
      },
    );
  }
  updateLikeCounter() {
    this.pharmacieService.updateLikeCounter(this.lengthLike);
  }
  openproductModal(modal: any, product: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openModal(modalTemplate: TemplateRef<any>, product: any) {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(
      result => {
        if (result === 'clearCart') {
          this.cartService.clearCartItems();
          if (this.openedProduct) {
            this.addToCartWithASpecificNumber();
          } else {
            this.addToCart(product, this.modalProductExist);
          }
        } else {
          console.log('User canceled the action.');
        }
      },
      reason => {
        console.log('Modal dismissed:', reason);
      },
    );
  }
  addToCartWithASpecificNumber() {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      //this.cartService.setPurchaseId(response.id);
      //   console.log('Product added to cart:', response);
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Product added to cart');
    }
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Product added to cart');
    }
  }
  toggleExpandDescription() {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
