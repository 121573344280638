import { Component, HostListener, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import SharedModule from '../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ICategory } from '../../../entities/category/category.model';
import { CategoryService } from '../../../entities/category/service/category.service';
import { IPharmacy } from '../pharmacy.model';
import { PharmacyService } from '../service/pharmacy.service';
import { PharmacyFormService, PharmacyFormGroup } from './pharmacy-form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  selector: 'jhi-pharmacy-update',
  templateUrl: './pharmacy-update.component.html',
  imports: [SharedModule, FormsModule, ReactiveFormsModule],
})
export class PharmacyUpdateComponent implements OnInit {
  isSaving = false;
  pharmacy: IPharmacy | null = null;

  categoriesSharedCollection: ICategory[] = [];
  nextValidation: boolean = false;
  ActivatedEmailAdmin: boolean = false;

  editForm: PharmacyFormGroup = this.pharmacyFormService.createPharmacyFormGroup();
  currentUrl: string;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    protected pharmacyService: PharmacyService,
    protected pharmacyFormService: PharmacyFormService,
    protected categoryService: CategoryService,
    protected activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.currentUrl = router.url;
  }

  compareCategory = (o1: ICategory | null, o2: ICategory | null): boolean => this.categoryService.compareCategory(o1, o2);

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ pharmacy }) => {
      this.pharmacy = pharmacy;
      if (pharmacy) {
        this.updateForm(pharmacy);
      }

      this.loadRelationshipsOptions();
    });
  }
  nextValidationPage() {
    this.nextValidation = true;
  }
  previousState(): void {
    this.ActivatedEmailAdmin = true;
  }

  save(): void {
    this.isSaving = true;
    const pharmacy = this.pharmacyFormService.getPharmacy(this.editForm);
    if (pharmacy.id !== null) {
      this.subscribeToSaveResponse(this.pharmacyService.update(pharmacy));
    } else {
      this.subscribeToSaveResponse(this.pharmacyService.create(pharmacy));
    }
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IPharmacy>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(): void {
    this.toastr.success('Votre compte a été validé avec succès');

    this.previousState();
  }

  protected onSaveError(): void {
    this.toastr.error('Erreur de validation du compte');
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }

  protected updateForm(pharmacy: IPharmacy): void {
    this.pharmacy = pharmacy;
    this.pharmacyFormService.resetForm(this.editForm, pharmacy);

    this.categoriesSharedCollection = this.categoryService.addCategoryToCollectionIfMissing<ICategory>(
      this.categoriesSharedCollection,
      ...(pharmacy.categories ?? []),
    );
  }

  protected loadRelationshipsOptions(): void {
    this.categoryService
      .query()
      .pipe(map((res: HttpResponse<ICategory[]>) => res.body ?? []))
      .pipe(
        map((categories: ICategory[]) =>
          this.categoryService.addCategoryToCollectionIfMissing<ICategory>(categories, ...(this.pharmacy?.categories ?? [])),
        ),
      )
      .subscribe((categories: ICategory[]) => (this.categoriesSharedCollection = categories));
  }
}
