<div class="row">
  <div class="col-md-3">
    <span class="hipster img-fluid rounded"></span>
  </div>

  <div class="col-md-9">
    <h1 class="display-4"><span jhiTranslate="home.title">PharmaGo</span> (Pharma)</h1>

    <p class="lead" jhiTranslate="home.subtitle">This is your homepage</p>

    <div>
      @if (account !== null) {
        <div class="alert alert-success">
          @if (account) {
            <span id="home-logged-message" jhiTranslate="home.logged.message" [translateValues]="{ username: account.login }"
              >You are logged in as user &quot;{{ account.login }}&quot;.</span
            >
          }
        </div>
      } @else {
        <div class="alert alert-warning">
          <span jhiTranslate="global.messages.info.authenticated.prefix">If you want to </span>
          <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a
          ><span jhiTranslate="global.messages.info.authenticated.suffix"
            >, you can try the default accounts:<br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;) <br />- User
            (login=&quot;user&quot; and password=&quot;user&quot;).</span
          >
        </div>

        <div class="alert alert-warning">
          <span jhiTranslate="global.messages.info.register.noaccount">You don&apos;t have an account yet?</span>&nbsp;
          <a class="alert-link" routerLink="/account/register" jhiTranslate="global.messages.info.register.link">Register a new account</a>
        </div>
      }
    </div>

    <p jhiTranslate="home.question">If you have any question on JHipster:</p>

    <ul>
      <li>
        <a href="https://www.jhipster.tech/" target="_blank" rel="noopener noreferrer" jhiTranslate="home.link.homepage"
          >JHipster homepage</a
        >
      </li>
      <li>
        <a
          href="https://stackoverflow.com/tags/jhipster/info"
          target="_blank"
          rel="noopener noreferrer"
          jhiTranslate="home.link.stackoverflow"
          >JHipster on Stack Overflow</a
        >
      </li>
      <li>
        <a
          href="https://github.com/jhipster/generator-jhipster/issues?state=open"
          target="_blank"
          rel="noopener noreferrer"
          jhiTranslate="home.link.bugtracker"
          >JHipster bug tracker</a
        >
      </li>
      <li>
        <a href="https://gitter.im/jhipster/generator-jhipster" target="_blank" rel="noopener noreferrer" jhiTranslate="home.link.chat"
          >JHipster public chat room</a
        >
      </li>
      <li>
        <a href="https://twitter.com/jhipster" target="_blank" rel="noopener noreferrer" jhiTranslate="home.link.follow"
          >follow &#64;jhipster on Twitter</a
        >
      </li>
    </ul>

    <p>
      <span jhiTranslate="home.like">If you like JHipster, don&apos;t forget to give us a star on</span>
      <a href="https://github.com/jhipster/generator-jhipster" target="_blank" rel="noopener noreferrer" jhiTranslate="home.github"
        >GitHub</a
      >!
    </p>
  </div>
</div>
