import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Account } from '../../../core/auth/account.model';
import { AccountService } from '../../../core/auth/account.service';
import { AuthServerProvider } from '../../../core/auth/auth-jwt.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleService } from 'app/pharmaBackOffice/services/Schedule/schedule.service';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { CalendarModule } from 'primeng/calendar';
@Component({
  selector: 'jhi-pharmacy-account',
  standalone: true,
  imports: [FormsModule, NgbModule, NgbDropdownModule, CommonModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './pharmacy-account.component.html',
  styleUrl: './pharmacy-account.component.scss',
})
export class PharmacyAccountComponent implements OnInit {
  @ViewChild('modalScheduler') modalScheduler: any;
  date: Date[] | undefined;
  profileImageUrl: string | ArrayBuffer | null = null;
  account!: Account;
  scheduleForm: FormGroup;
  isEditMode = false;
  selectedSchedule: any = null;

  constructor(
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
  ) {
    this.scheduleForm = this.fb.group({
      day: ['', Validators.required],
      openingHour: ['', Validators.required],
      closingHour: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadProfile();
    this.getSchedule();
  }

  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
    });
  }

  updateProfile(): void {
    this.accountService.save(this.account).subscribe(res => {
      this.accountService.setAccount(this.account);
      this.loadProfile();
      this.toastrService.success('Profile updated successfully');
    });
  }
  openModal(isEdit: boolean, schedule: any = null) {
    this.isEditMode = isEdit;
    this.selectedSchedule = schedule;

    if (isEdit && schedule) {
      // Pré-remplir le formulaire pour modification
      this.scheduleForm.patchValue({
        day: schedule.day,
        openingHour: schedule.openingHour,
        closingHour: schedule.closingHour,
      });
    } else {
      // Réinitialiser le formulaire pour ajout
      this.scheduleForm?.reset();
    }

    this.modalService.open(this.modalScheduler);
  }

  // Fermer le modal
  closeModal() {
    this.modalService.dismissAll();
  }

  // Formater l'heure pour correspondre à "HH:mm:ss"
  formatTime(time: string): string {
    return time ? `${time}:00` : ''; // Ajouter ":00" à l'heure sélectionnée
  }

  // Soumettre le formulaire
  submitSchedule() {
    if (this.scheduleForm?.invalid) {
      this.toastrService.error('Veuillez remplir tous les champs');
      return;
    }

    const scheduleData = {
      id: this.isEditMode && this.selectedSchedule ? this.selectedSchedule.id : null, // Récupérer l'ID si mode modification
      openingHour: this.formatTime(this.scheduleForm?.value.openingHour), // Format HH:mm:ss
      closingHour: this.formatTime(this.scheduleForm?.value.closingHour), // Format HH:mm:ss
      day: this.scheduleForm?.value.day,
      pharmacy: {
        id: this.account.applicationUser?.pharmacy?.id, // Valeur fixe, modifiable si nécessaire
      },
    };

    if (this.isEditMode && scheduleData.id) {
      // Modifier un horaire existant
      this.scheduleService.putSchedules(scheduleData.id, scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire modifié avec succès');
          this.getSchedule(response.id);
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de la modification de l'horaire");
        },
      );
    } else {
      // Ajouter un nouvel horaire
      this.scheduleService.addSchedules(scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire ajouté avec succès');
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de l'ajout de l'horaire");
        },
      );
    }
  }
  schedules: any;
  getSchedule(id_schedule?: any) {
    this.scheduleService.getSchedules(id_schedule).subscribe({
      next: response => {
        this.schedules = response;
      },
      error: error => {
        this.toastrService.error('Erreur lors de la récupération des horaires');
      },
    });
  }
  onDeleteClick(id_schedule: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet horaire ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteSchedule(id_schedule);
      }
    });
  }
  deleteSchedule(purshase_id: any) {
    this.scheduleService.deleteSchedules(purshase_id).subscribe({
      next: res => {
        this.getSchedule();
      },
      error: err => {
        console.log(err);
      },
    });
  }
}
