import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SharedModule from '../shared/shared.module';

@Component({
  selector: 'jhi-privacy-policy',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private translateService: TranslateService) {}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    const storedLang = localStorage.getItem('selectedLanguage');
    if (storedLang) {
      this.translateService.use(storedLang);
    } else this.translateService.use('en');
  }
}
