import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jhi-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  likeImagePath: string = '../../content/images/icons/Icon feather-heart (3).svg';
  @Input() product: any;
  constructor() {}

  ngOnInit(): void {}

  toggleLike(): void {
    if (this.likeImagePath === '../../content/images/icons/Icon feather-heart (3).svg') {
      this.likeImagePath = '../../content/images/icons/Icon feather-heart (4).svg';
    } else {
      this.likeImagePath = '../../content/images/icons/Icon feather-heart (3).svg';
    }
  }
}
