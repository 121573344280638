import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jhi-pharmacy-card',
  templateUrl: './pharmacy-card.component.html',
  styleUrls: ['./pharmacy-card.component.scss'],
})
export class PharmacyCardComponent implements OnInit {
  likeImagePath: string = '../../content/images/Icon feather-heart (1).svg';

  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  toggleLike($event: Event): boolean {
    $event.preventDefault;
    if (this.likeImagePath === '../../content/images/Icon feather-heart (1).svg') {
      this.likeImagePath = '../../content/images/Icon feather-heart (2).svg';
    } else {
      this.likeImagePath = '../../content/images/Icon feather-heart (1).svg';
    }
    return false;
  }
}
